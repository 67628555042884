import React, { Component } from 'react'

import * as S from './styles'

export default class Blank extends Component {
  render () {
    const { filledIn, answer } = this.props

    return <S.BlankComponent>
      <S.Answer filledIn={filledIn}>{answer}</S.Answer>
    </S.BlankComponent>
  }
}
