import styled from 'styled-components'

import { Spinner } from 'app/ui-kit/Icons'

export const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledSpinner = styled(Spinner)``
