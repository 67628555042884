import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import * as S from './styles'
import { Button } from 'app/ui-kit'
import Analytics from 'app/analytics'
import { getIn } from 'app/utilities'
import { floatingNewsImageMap } from 'app/data'

const mapStateToProps = (state) => {
  const { floatingPersonNum } = state.app
  return { floatingPersonNum }
}
@withRouter
@connect(mapStateToProps)
export default class AppStoreButton extends Component {
  clickHandler = () => {
    const { pathname } = this.props.location
    const floatingPersonImageName = getIn(floatingNewsImageMap[this.props.floatingPersonNum], ['name'])

    Analytics.event({
      eventType: 'BUTTON CLICK',
      pathname,
      props: {
        buttonName: 'App Store CTA',
        floatingPersonImage: floatingPersonImageName
      }
    })
  }

  render () {
    return <S.AppStoreButtonComponent {...this.props}>
      <S.StyledA href={process.env.APP_STORE_URL} target='_blank' onClick={this.clickHandler}>
        <Button size={this.props.size}>
          <S.Apple size={this.props.size} />
          <S.ButtonText>Download the App</S.ButtonText>
        </Button>
      </S.StyledA>
    </S.AppStoreButtonComponent>
  }
}
