export const brand = '#48A1BD'
export const brandSecondary = '#747FA6'
export const brand20alpha = 'rgba(72, 161, 189, .2)'
export const brand30alpha = 'rgba(72, 161, 189, .3)'
export const brand40alpha = 'rgba(72, 161, 189, .4)'
export const lightBlue = '#EBF8FF'
export const white = '#FFFFFF'
export const white30 = 'rgba(255, 255, 255, 0.3)'
export const black08 = 'rgba(0, 0, 0, 0.08)'
export const error = '#BD4848'

export const gray = '#C4C4C4'
export const darkGray = '#16161D'
export const violetDark = '#29182F'
export const darkBlue = '#3D496E'
export const darkBlue50Alpha = 'rgba(61, 73, 110, 0.5)'
export const midnightBlue = '#2F3958'
export const royalBlue = '#354266'
export const periwinkle = '#6C81B4'
export const periwinkleLight = '#BDC9EA'
export const periwinkle20alpha = 'rgba(108, 129, 180, .2)'
export const blue = '#4090BD'
export const skyBlue = '#CBE7F2'
export const skyBlueLight = '#E6F6FC'
export const peach = '#E56135'
export const peachLight = '#FFB095'
export const peachExtraLight = '#FFDACD'
export const peachMedium = '#F68560'
export const cream = '#FDECC1'
export const offWhite = '#FFFEF9'
export const inputBorder = 'rgba(143, 143, 143, 0.5)'
export const lightGray = '#949494'
export const graphRules = '#D5E2E8'

export const fontPrimary = violetDark
