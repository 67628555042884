import styled from 'styled-components'
import { Colors, Breakpoints, Type } from 'app/styles'
import { cardBg } from 'app/assets/images'

export const SolutionPanel = styled.div`
  width: 560px;
  padding: 0;
  position: relative;
  flex: 1;

  @media(max-width: ${Breakpoints.solutionComponent2}){
    width: auto;
    margin-bottom: 20px;
  }

  @media(max-width: ${Breakpoints.solutionComponent3}){
    margin-bottom: 30px;
  }
`

export const SolutionPanelComponent = styled.div`
  width: 560px;
  position: relative;
  flex: 1;
  left: 75px;

  @media(max-width: ${Breakpoints.largest}){
    transform: scale(.83);
    left: 20px;
  }

  @media(max-width: ${Breakpoints.solutionComponent1}){
    transform: scale(.75);
  }

  @media(max-width: ${Breakpoints.solutionComponent2}){
    transform: scale(1);
    left: 0;
  }

  @media(max-width: ${Breakpoints.solutionComponent3}){
    width: 325px;
  }

  @media(max-width: ${Breakpoints.iphone5}){
    width: 100vw;
    padding: 0 20px;
    box-sizing: border-box;
  }
`

export const Shadow = styled.div`
  width: 560px;
  height: 412px;
  background-image: url(${cardBg});
  background-size: cover;
  position: absolute;
  right: -30px;
  top: 30px;

  @media(max-width: ${Breakpoints.solutionComponent3}){
    display: none;
  }
`

export const SectionMaxWidth = styled.div`
  max-width: 1120px;
  display: flex;
  justify-content: space-between;
`

export const SolutionText = styled.div`
  width: 410px;
  margin-left: auto;
  padding-right: 120px;

  @media(max-width: ${Breakpoints.solutionComponent2}){
    width: auto;
    padding: 0 88px;
    margin-left: 0;
  }

  @media(max-width: ${Breakpoints.solutionComponent3}){
    margin-bottom: 0;
    padding: 0 25px;
    max-width: 250px;
  }
`

export const NumberCircle = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 25px;
  border: 1px solid ${Colors.periwinkleLight};
  background-color: ${Colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${Colors.periwinkle};
  position: absolute;
  right: -26px;
  top: 50%;
  margin-top: -25px;
  ${Type.label};
  font-weight: bold;

  &:before {
    content: '';
    display: block;
    width: 63px;
    height: 1px;
    background-color: ${Colors.periwinkleLight};
    position: absolute;
    right: -63px;
  }

  @media(max-width: ${Breakpoints.solutionComponent2}){
    display: none;
  }
`

export const SolutionSection = styled.div`
  display: flex;
  height: 564px;
  justify-content: center;
  align-items: center;
  position: relative;

  &:nth-child(odd) {
    flex-direction: row-reverse;

    ${SolutionText} {
      padding-left: 120px;
      padding-right: 0;
      margin-right: auto;
      margin-left: 0;

      @media(max-width: ${Breakpoints.solutionComponent2}) {
        padding: 0 25px;
      }

      @media(max-width: ${Breakpoints.solutionComponent3}) {
        padding: 0 25px;
      }
    }

    ${NumberCircle} {
      left: -26px;
      right: initial;

      @media(max-width: ${Breakpoints.smallest}) {
        display: none;
      }

      &:before {
        left: -63px;
      }
    }

    ${Shadow} {
      left: -30px;
      right: initial;
      transform: scaleX(-1);
    }

    ${SolutionPanelComponent} {
      left: -75px;

      @media(max-width: ${Breakpoints.largest}){
        left: -20px;
      }

      @media(max-width: ${Breakpoints.solutionComponent2}){
        left: 0;
      }
    }
  }

  @media(max-width: ${Breakpoints.solutionComponent2}) {
    flex-direction: column;
    align-items: center;
    height: auto;
    margin-bottom: 80px;

    &:nth-child(odd) {
      flex-direction: column;
    }
  }
`

export const StyledH3 = styled.h3`
  margin-bottom: 20px;
  color: ${Colors.darkBlue};

  @media(max-width: ${Breakpoints.solutionComponent2}){
    margin-bottom: 0;
    text-align: center;
  }
`

export const SolutionDescription = styled.div`
  @media(max-width: ${Breakpoints.solutionComponent2}){
    display: none;
  }
`

export const SolutionImage = styled.div`
  width: 560px;
`
