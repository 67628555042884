import React, { Component } from 'react'

import * as S from './styles'
import resourceData from './resourceData'
import Contact from '../Home/Contact'

export default class Resources extends Component {
  renderResources = () => {
    const items = Object.values(resourceData)
    return items.map((item, index) => {
      return <S.ResourceSection key={index}>
        <S.SectionTitle>{item.title}</S.SectionTitle>
        <S.Links>
          { item.links.map((link, index) => (
            <S.ResourceLink hasTitle={!!link.title} key={index}>
              <S.StyledA href={link.url} target='_blank'>
                { link.title && <S.ArticleTitle>{link.title}</S.ArticleTitle> }
                <S.Source>{link.source}</S.Source>
              </S.StyledA>
            </S.ResourceLink>
          )) }
        </S.Links>
      </S.ResourceSection>
    })
  }

  render () {
    return <S.ResourcesPage>
      <S.Hero>
        <S.Title>Resources</S.Title>
        <S.Fade />
      </S.Hero>
      <S.StyledBreakerWave />
      <S.MaxWidth>
        { this.renderResources() }
      </S.MaxWidth>
      <Contact />
    </S.ResourcesPage>
  }
}
