import React, { Component } from 'react'

import * as S from './styles'
import { floatingNewsImageMap } from 'app/data'
import { storm, newsToday, news, newsPaper } from 'app/assets/images'
import { getIn } from 'app/utilities'

export default class FloatingNews extends Component {
  render () {
    const imageUrl = getIn(floatingNewsImageMap[this.props.floatingPersonNum], ['desktopImage'])
    return <S.FloatingNewsComponent>
      <S.Storm src={storm} />
      <S.FloatingGirl imageUrl={imageUrl} />
      <S.NewsToday src={newsToday} />
      <S.News src={news} />
      <S.NewsPaper src={newsPaper} />
    </S.FloatingNewsComponent>
  }
}
