import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

import * as S from './styles'
import { openModal } from 'app/store/app/actions'
import Analytics from 'app/analytics'

@withRouter
@connect(null, { openModal })
export default class Contact extends Component {
  openContactUsModal = () => {
    this.props.openModal({ modalName: 'ContactUsModal' })

    Analytics.event({
      eventType: 'BUTTON CLICK',
      pathname: this.props.location.pathname,
      props: {
        buttonName: 'Open Contact Us'
      }
    })
  }

  clickFaqButton = () => {
    Analytics.event({
      eventType: 'BUTTON CLICK',
      pathname: this.props.location.pathname,
      props: {
        buttonName: 'Read FAQ'
      }
    })
  }

  render () {
    return <S.ContactComponent>
      <S.Title>We care how you feel about the news.</S.Title>
      <S.Description>Have questions? Want to share your thoughts? Talk to us.</S.Description>
      <S.Buttons>
        <Link to='/frequently-asked-questions' onClick={this.clickFaqButton}><S.FaqButton secondary>Read our FAQ</S.FaqButton></Link>
        <S.StyledButton secondary onClick={this.openContactUsModal}>Contact us</S.StyledButton>
      </S.Buttons>
    </S.ContactComponent>
  }
}
