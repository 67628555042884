import React from 'react'

export default (props) =>
  <svg {...props} width='100' height='100' viewBox='0 0 100 100' fill='none'>
    <path d='M89.2 23.4H79.2V13.2C79.2 9.9 76.6 7.2 73.3 7C73.2 7 73.1 7 73 7H67.6H29C28.9 7 28.8 7 28.7 7C25.7 7.2 23.2 9.5 22.9 12.5C22.9 12.7 22.9 12.9 22.9 13.1V34.2H16.9C13.1 34.2 10 37.3 10 41.1V72.2C10 76 13.1 79.1 16.9 79.1H20.4V92.6C20.4 92.7 20.4 92.7 20.4 92.8C20.4 92.8 20.4 92.8 20.4 92.9V93C20.4 93 20.4 93 20.4 93.1C20.4 93.1 20.4 93.1 20.4 93.2L20.5 93.3L20.6 93.4C20.6 93.4 20.6 93.4 20.7 93.4C20.7 93.4 20.7 93.4 20.8 93.4C20.9 93.4 20.9 93.4 20.9 93.4C20.9 93.4 20.9 93.4 21 93.4C21.1 93.4 21.1 93.4 21.2 93.4C21.3 93.4 21.3 93.4 21.4 93.4C21.4 93.4 21.4 93.4 21.5 93.4H21.6C21.6 93.4 21.6 93.4 21.7 93.4C21.7 93.4 21.7 93.4 21.8 93.4L21.9 93.3L35.9 79.3H74.5C78.3 79.3 81.4 76.2 81.4 72.4V48C82.2 48.4 83.1 48.6 84 48.6C87.4 48.6 90.2 45.8 90.2 42.4V24.2C90 23.8 89.6 23.4 89.2 23.4ZM73 8.6C75.6 8.6 77.6 10.7 77.6 13.2V24.1V35C76.6 34.5 75.5 34.2 74.3 34.2H68.3V13.2C68.4 10.7 70.4 8.6 73 8.6ZM79.7 72.2C79.7 75.2 77.3 77.6 74.3 77.6H35.4H35.3C35.3 77.6 35.3 77.6 35.2 77.6H35.1C35.1 77.6 35 77.6 35 77.7L34.9 77.8L22 90.7V78.4C22 78 21.7 77.6 21.2 77.6H16.9C13.9 77.6 11.5 75.2 11.5 72.2V41.2C11.5 38.2 13.9 35.8 16.9 35.8H22.9V67.5C22.9 67.9 23.2 68.3 23.7 68.3C24.2 68.3 24.5 68 24.5 67.5V13.2C24.5 10.6 26.6 8.6 29.1 8.6H67.6H69C67.7 9.7 66.8 11.4 66.8 13.3V67.6C66.8 68 67.1 68.4 67.6 68.4C68.1 68.4 68.4 68.1 68.4 67.6V35.8H74.4C75.7 35.8 77 36.3 78 37.2C79.1 38.2 79.8 39.7 79.8 41.2L79.7 72.2ZM88.4 42.4C88.4 45 86.3 47 83.8 47C82.9 47 82 46.7 81.2 46.2V41.1C81.2 39.3 80.5 37.5 79.1 36.2V25H88.4V42.4Z' fill='#48A1BD' />
    <path d='M38.1984 18.4H53.0984C53.4984 18.4 53.8984 18.1 53.8984 17.6C53.8984 17.2 53.5984 16.8 53.0984 16.8H38.1984C37.7984 16.8 37.3984 17.1 37.3984 17.6C37.3984 18.1 37.6984 18.4 38.1984 18.4Z' fill='#48A1BD' />
    <path d='M62.2992 22H28.9992C28.5992 22 28.1992 22.3 28.1992 22.8C28.1992 23.3 28.4992 23.6 28.9992 23.6H62.2992C62.6992 23.6 63.0992 23.3 63.0992 22.8C63.0992 22.3 62.6992 22 62.2992 22Z' fill='#48A1BD' />
    <path d='M62.2992 27.1001H28.9992C28.5992 27.1001 28.1992 27.4001 28.1992 27.9001C28.1992 28.3001 28.4992 28.7001 28.9992 28.7001H62.2992C62.6992 28.7001 63.0992 28.4001 63.0992 27.9001C62.9992 27.4001 62.6992 27.1001 62.2992 27.1001Z' fill='#48A1BD' />
    <path d='M62.2992 32.2H28.9992C28.5992 32.2 28.1992 32.5 28.1992 33C28.1992 33.5 28.4992 33.7999 28.9992 33.7999H62.2992C62.6992 33.7999 63.0992 33.5 63.0992 33C63.0992 32.5 62.6992 32.2 62.2992 32.2Z' fill='#48A1BD' />
    <path d='M62.2992 37.3H28.9992C28.5992 37.3 28.1992 37.6 28.1992 38.1C28.1992 38.5 28.4992 38.9 28.9992 38.9H62.2992C62.6992 38.9 63.0992 38.6 63.0992 38.1C63.0992 37.6 62.6992 37.3 62.2992 37.3Z' fill='#48A1BD' />
    <path d='M62.2992 52.6001H28.9992C28.5992 52.6001 28.1992 52.9001 28.1992 53.4001C28.1992 53.9001 28.4992 54.2001 28.9992 54.2001H62.2992C62.6992 54.2001 63.0992 53.9001 63.0992 53.4001C62.9992 53.0001 62.6992 52.6001 62.2992 52.6001Z' fill='#48A1BD' />
    <path d='M62.2992 57.7H28.9992C28.5992 57.7 28.1992 58 28.1992 58.5C28.1992 58.9 28.4992 59.2999 28.9992 59.2999H62.2992C62.6992 59.2999 63.0992 59 63.0992 58.5C62.9992 58.1 62.6992 57.7 62.2992 57.7Z' fill='#48A1BD' />
    <path d='M62.2992 62.8H28.9992C28.5992 62.8 28.1992 63.1 28.1992 63.6C28.1992 64.1 28.4992 64.4001 28.9992 64.4001H62.2992C62.6992 64.4001 63.0992 64.1 63.0992 63.6C63.0992 63.1 62.6992 62.8 62.2992 62.8Z' fill='#48A1BD' />
  </svg>
