import styled from 'styled-components'

import { Breakpoints, Colors } from 'app/styles'
import { skyBlueWave } from 'app/assets/images'

export const PauseReflectComponent = styled.div`
  background-color: ${Colors.skyBlue};
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 372px;

  @media(max-width: ${Breakpoints.main}) {
    margin-top: 314px;
  }
`

export const Title = styled.h2`
  margin-bottom: 35px;

  @media(max-width: ${Breakpoints.main}) {
    text-align: center;
    padding: 0 25px;
    margin-bottom: 20px;
  }
`
export const Description = styled.div`
  max-width: 684px;

  @media(max-width: ${Breakpoints.main}) {
    max-width: 320px;
    text-align: center;
    padding: 0 25px;
  }
`

export const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 82px;
  margin-left: 20px;

  @media(max-width: ${Breakpoints.largest}) {
    flex-direction: column;
    align-items: center;
  }

  @media(max-width: ${Breakpoints.main}){
    margin-top: 50px;
    margin-left: 0;
  }
`

export const MaxWidth = styled.div`
  max-width: 1140px;
  top: -120px;
  position: relative;
  display: flex;
  flex-direction: column;

  @media(max-width: ${Breakpoints.main}) {
    max-width: 100vw;
    top: -190px;
    align-items: center;
    margin-bottom: -98px;
  }
`

export const SkyBlueWave = styled.div`
  position: absolute;
  top: -372px;
  background-image: url(${skyBlueWave});
  background-repeat: no-repeat;
  background-position: top;
  width: 1800px;
  height: 373px;
  background-size: cover;

  @media(max-width: ${Breakpoints.main}) {
    width: 1322px;
    height: 285px;
    top: -285px;
  }
`
