import styled from 'styled-components'

import { Colors, Type, Breakpoints } from 'app/styles'
import { iceberg1, iceberg2, topBlueBackground, mobileProblemWave } from 'app/assets/images'

export const TheProblemComponent = styled.div`
  background-color: ${Colors.darkBlue};
  display: flex;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  margin-top: 455px;
  z-index: 2;
  position: relative;

  @media(max-width: ${Breakpoints.smallest}) {
    margin-top: 105px;
    padding: 0 25px;
    background-image: linear-gradient(${Colors.darkBlue}, ${Colors.darkBlue});
    background-color: ${Colors.darkBlue50Alpha};
    background-blend-mode: multiply;
  }
`
export const TopBlueWave = styled.div`
  position: absolute;
  top: -1088px;
  background-image: url(${topBlueBackground});
  background-repeat: no-repeat;
  background-size: 1800px auto;
  background-position: top;
  width: 1800px;
  height: 1089px;
  background-size: cover;

  @media(max-width: ${Breakpoints.smallest}) {
    top: -118px;
    background-image: url(${mobileProblemWave});
    background-size: 834px auto;
  }
`
export const SuperTitle = styled.h5`
  color: ${Colors.cream};
  margin-bottom: 32px;
  margin: 0 0 52px 0;
  z-index: 2;

  @media(max-width: ${Breakpoints.main}) {
    margin-bottom: 16px
  }
`
export const Title = styled.div`
  margin: 0 0 52px 0;
  color: ${Colors.white};
  text-align: center;
  max-width: 900px;
  z-index: 2;

  @media(max-width: ${Breakpoints.main}) {
    max-width: 374px;
    margin-bottom: 36px
  }
`
export const StyledH2 = styled.h2`
  @media(max-width: ${Breakpoints.smallest}) {
    display: inline;
  }
`

export const ItalicH2 = styled.h2`
  text-align: center;
  font-style: italic;

  @media(max-width: ${Breakpoints.smallest}) {
    display: inline;
  }
`

export const BodyCopy = styled.div`
  max-width: 640px;
  padding: 0 20px;
  margin-bottom: 350px;
  z-index: 2;
  position: relative;
  color: ${Colors.white};
  position: relative;

  @media(max-width: ${Breakpoints.main}) {
    max-width: 442px;
    margin-bottom: 280px;
  }

  @media(max-width: ${Breakpoints.iphone5}) {
    padding: 0;
  }
`

export const Paragraph = styled.p`
  ${Type.body};
  text-align: center;
`

export const Iceberg1 = styled.div`
  width: 678px;
  height: 824px;
  background-image: url(${iceberg1});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;

  @media(max-width: ${Breakpoints.main}) {
    width: 328px;
    height: 398px;
  }
`
export const Iceberg1Wrap = styled.div`
  position: absolute;
  left: -678px;
  top: -130px;

  @media(max-width: ${Breakpoints.main}) {
    left: -272px;
    top: 214px;
  }

  @media(max-width: ${Breakpoints.icebergPosition}) {
    left: -222px;
    top: 344px;
  }

  @media(max-width: ${Breakpoints.iphone5}) {
    top: 400px;
  }
`
export const Iceberg2Wrap = styled.div`
  position: absolute;
  right: -678px;
  top: -136px;

  @media(max-width: ${Breakpoints.main}) {
    right: -272px;
    top: 214px;
  }

  @media(max-width: ${Breakpoints.icebergPosition}) {
    right: -222px;
    top: 344px;
  }

  @media(max-width: ${Breakpoints.iphone5}) {
    top: 400px;
  }
`

export const Iceberg2 = styled.div`
  width: 694px;
  height: 824px;
  background-image: url(${iceberg2});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: 1;

  @media(max-width: ${Breakpoints.main}) {
    width: 337px;
    height: 398px;
  }
`
export const StyledA = styled.a`
  color: ${Colors.peachLight};
  font-weight: 500;

  &:hover {
    color: ${Colors.peachExtraLight}
  }
`

export const ContentWrap = styled.div`
  margin-top: -20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  @media(max-width: ${Breakpoints.main}) {
    position: relative;
    margin-top: -380px;
  }

  @media(max-width: ${Breakpoints.smallest}) {
    margin-top: 0;
  }
`
