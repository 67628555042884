import { createGlobalStyle } from 'styled-components'

import PensumProBookTTF from '../assets/fonts/PensumPro-Book.ttf'
import PensumProBookWOFF from '../assets/fonts/PensumPro-Book.woff'
import PensumProBookWOFF2 from '../assets/fonts/PensumPro-Book.woff2'

import PensumProBoldTTF from '../assets/fonts/PensumPro-Bold.ttf'
import PensumProBoldWOFF from '../assets/fonts/PensumPro-Bold.woff'
import PensumProBoldWOFF2 from '../assets/fonts/PensumPro-Bold.woff2'

import PensumProBoldItalicTTF from '../assets/fonts/PensumPro-BoldItalic.ttf'
import PensumProBoldItalicWOFF from '../assets/fonts/PensumPro-BoldItalic.woff'
import PensumProBoldItalicWOFF2 from '../assets/fonts/PensumPro-BoldItalic.woff2'

import PensumProExtraBoldTTF from '../assets/fonts/PensumPro-ExtraBold.ttf'
import PensumProExtraBoldWOFF from '../assets/fonts/PensumPro-ExtraBold.woff'
import PensumProExtraBoldWOFF2 from '../assets/fonts/PensumPro-ExtraBold.woff2'

import RingsideWideBoldTTF from '../assets/fonts/RingsideWide-Bold.ttf'
import RingsideWideBoldWOFF from '../assets/fonts/RingsideWide-Bold.woff'
import RingsideWideBoldWOFF2 from '../assets/fonts/RingsideWide-Bold.woff2'

import RingsideWideSemiTTF from '../assets/fonts/RingsideWide-Semi.ttf'
import RingsideWideSemiWOFF from '../assets/fonts/RingsideWide-Semi.woff'
import RingsideWideSemiWOFF2 from '../assets/fonts/RingsideWide-Semi.woff2'

import RingsideBoldTTF from '../assets/fonts/RingsideRegular-Bold.ttf'
import RingsideBoldWOFF from '../assets/fonts/RingsideRegular-Bold.woff'
import RingsideBoldWOFF2 from '../assets/fonts/RingsideRegular-Bold.woff2'

import RingsideMedTTF from '../assets/fonts/RingsideRegular-Med.ttf'
import RingsideMedWOFF from '../assets/fonts/RingsideRegular-Med.woff'
import RingsideMedWOFF2 from '../assets/fonts/RingsideRegular-Med.woff2'

import RingsideLightTTF from '../assets/fonts/RingsideRegular-Light.ttf'
import RingsideLightWOFF from '../assets/fonts/RingsideRegular-Light.woff'
import RingsideLightWOFF2 from '../assets/fonts/RingsideRegular-Light.woff2'

import RingsideCondensedSemiTTF from '../assets/fonts/RingsideCondensed-Semi.ttf'
import RingsideCondensedSemiWOFF from '../assets/fonts/RingsideCondensed-Semi.woff'
import RingsideCondensedSemiWOFF2 from '../assets/fonts/RingsideCondensed-Semi.woff2'

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'PensumPro';
    src: url(${PensumProBookWOFF2}) format('woff2'),
         url(${PensumProBookWOFF}) format('woff'),
         url(${PensumProBookTTF}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'PensumPro';
    src: url(${PensumProBoldWOFF2}) format('woff2'),
         url(${PensumProBoldWOFF}) format('woff'),
         url(${PensumProBoldTTF}) format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'PensumPro';
    src: url(${PensumProBoldItalicWOFF2}) format('woff2'),
         url(${PensumProBoldItalicWOFF}) format('woff'),
         url(${PensumProBoldItalicTTF}) format('truetype');
    font-weight: bold;
    font-style: italic;
  }

  @font-face {
    font-family: 'PensumPro';
    src: url(${PensumProExtraBoldWOFF2}) format('woff2'),
         url(${PensumProExtraBoldWOFF}) format('woff'),
         url(${PensumProExtraBoldTTF}) format('truetype');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'RingsideWide';
    src: url(${RingsideWideBoldWOFF2}) format('woff2'),
         url(${RingsideWideBoldWOFF}) format('woff'),
         url(${RingsideWideBoldTTF}) format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'RingsideWide';
    src: url(${RingsideWideSemiWOFF2}) format('woff2'),
         url(${RingsideWideSemiWOFF}) format('woff'),
         url(${RingsideWideSemiTTF}) format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'RingsideCondensed';
    src: url(${RingsideCondensedSemiWOFF2}) format('woff2'),
         url(${RingsideCondensedSemiWOFF}) format('woff'),
         url(${RingsideCondensedSemiTTF}) format('truetype');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'Ringside';
    src: url(${RingsideBoldWOFF2}) format('woff2'),
         url(${RingsideBoldWOFF}) format('woff'),
         url(${RingsideBoldTTF}) format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Ringside';
    src: url(${RingsideMedWOFF2}) format('woff2'),
         url(${RingsideMedWOFF}) format('woff'),
         url(${RingsideMedTTF}) format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Ringside';
    src: url(${RingsideLightWOFF2}) format('woff2'),
         url(${RingsideLightWOFF}) format('woff'),
         url(${RingsideLightTTF}) format('truetype');
    font-weight: 200;
    font-style: normal;
  }
`
