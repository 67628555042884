import styled, { css } from 'styled-components'

import { Colors } from 'app/styles'
import { Check, X } from 'app/ui-kit/Icons'

export const CorrectnessIndicatorComponent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledCheck = styled(Check)`
  width: 0;
  opacity: 0;
  transition: all .3s ease-out;
  margin-left: 0;
  position: absolute;
  top: 50%;
  margin-top: -6px;
  right: 15px;

  ${props => props.state === 'correct' && css`
    width: 13px;
    opacity: 1;
  `}
`
export const StyledX = styled(X)`
  width: 0;
  opacity: 0;
  transition: all .25s ease-in-out;
  position: absolute;
  top: 50%;
  margin-top: -14px;
  right: 15px;

  line {
    stroke: ${Colors.white};
  }

  ${props => props.state === 'incorrect' && css`
    width: 13px;
    opacity: 1;
  `}
`
