import React, { Component } from 'react'
import { withRouter, matchPath } from 'react-router-dom'

import * as S from './styles'
import routes from '../../routes/routes'
import Analytics from 'app/analytics'

@withRouter
export default class Footer extends Component {
  getThemeForRoute = () => {
    const { location } = this.props
    const activeRoute = routes[0].routes.find(route => matchPath(location.pathname, route))
    return activeRoute.navTheme
  }

  clickFooterLink = (name) => {
    Analytics.event({
      eventType: 'FOOTER LINK CLICK',
      pathname: this.props.location.pathname,
      props: {
        name
      }
    })
  }

  render () {
    const theme = this.getThemeForRoute()
    if (theme === 'hidden') return null

    return <S.FooterComponent>
      <S.AllTurtlesAndMobileSocial>
        <S.ATMaxWidth>
          <a href='https://all-turtles.com/' target='_blank' onClick={() => { this.clickFooterLink('all turtles') }}>
            <S.AllTurtles>
              <S.StyledAllTurtlesLogo />
              <S.AllTurtlesVerticalRule />
              <S.StyledAllTurtlesProduct />
            </S.AllTurtles>
          </a>
          <S.Social>
            <a href={process.env.TWITTER_URL} onClick={() => { this.clickFooterLink('twitter') }}target='_blank'><S.StyledTwitter /></a>
            <a href={process.env.INSTAGRAM_URL} onClick={() => { this.clickFooterLink('instagram') }}target='_blank'><S.StyledInstagram /></a>
          </S.Social>
        </S.ATMaxWidth>
      </S.AllTurtlesAndMobileSocial>
      <S.Legal>
        <S.MaxWidth>
          <S.FaqAndPrivacy>
            <S.PrivacyPolicy to='/privacy-policy' onClick={() => { this.clickFooterLink('privacy policy') }}>Privacy Policy</S.PrivacyPolicy>
            <S.VerticalRule />
            <S.PrivacyPolicy to='/terms-of-use' onClick={() => { this.clickFooterLink('terms') }}>Terms</S.PrivacyPolicy>
            <S.VerticalRule />
          </S.FaqAndPrivacy>
          <S.Copyright>{`© ${new Date().getFullYear()} All Turtles Corporation`}</S.Copyright>
        </S.MaxWidth>
      </S.Legal>
    </S.FooterComponent>
  }
}
