import React, { Component } from 'react'
import { withRouter, matchPath } from 'react-router-dom'

import { PrivacyPolicyPage, MaxWidth, PageTitle, BoldSpan, BoldDiv } from './styles'
import routes from '../../routes/routes'

@withRouter
export default class PrivacyPolicy extends Component {
  getThemesForRoute = () => {
    const { location } = this.props
    const activeRoute = routes[0].routes.find(route => matchPath(location.pathname, route))
    return { theme: activeRoute.navTheme, mobileTheme: activeRoute.mobileNavTheme }
  }

  render () {
    const { theme } = this.getThemesForRoute()

    return pug`
      PrivacyPolicyPage
        MaxWidth
          PageTitle(theme=theme) Privacy policy
          p Effective date: May 29, 2019
          p All Turtles Corporation ("us", "we", or "our") operates the several mobile and web based applications (hereinafter referred to as the "Service").
          p This page informs you of our policies regarding the collection, use and disclosure of personal data when you use our Service and the choices you have associated with that data.
          p We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.
          h4 Definitions
          BoldDiv Service
          p Service refers to the various mobile and web based applications operated by All Turtles Corporation.
          BoldDiv Personal Data
          p Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).
          BoldDiv Usage Data
          p Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).
          BoldDiv Cookies
          p Cookies are small files stored on your device (computer or mobile device).
          BoldDiv Data Controller
          p Data Controller means the natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal information are, or are to be, processed.
          p For the purpose of this Privacy Policy, we are a Data Controller of your Personal Data.
          BoldDiv Data Processors (or Service Providers)
          p Data Processor (or Service Provider) means any natural or legal person who processes the data on behalf of the Data Controller.
          p We may use the services of various Service Providers in order to process your data more effectively.
          BoldDiv Data Subject (or User)
          p Data Subject is any living individual who is using our Service and is the subject of Personal Data.
          h4 Information Collection and Use
          p We collect several different types of information for various purposes to provide and improve our Service to you.
          BoldDiv Types of Data Collected
          BoldDiv Personal Data
          p While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:
          ul
            li Email address
            li First name and last name
            li Phone number
            li Cookies and Usage Data
          BoldDiv Usage Data
          p When you access the Service with a mobile device, we may collect certain information automatically, including, but not limited to, the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data ("Usage Data").
          BoldDiv Location Data
          p We may use and store information about your location if you give us permission to do so ("Location Data"). We use this data to provide features of our Service, to improve and customise our Service.
          p You can enable or disable location services when you use our Service at any time by way of your device settings.
          BoldDiv Tracking Cookies Data
          p We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.
          p Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyse our Service.
          p You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.
          p Examples of Cookies we use:
          ul
            li Session Cookies. We use Session Cookies to operate our Service.
            li Preference Cookies. We use Preference Cookies to remember your preferences and various settings.
            li Security Cookies. We use Security Cookies for security purposes.
          h4 Use of Data
          p All Turtles Corporation uses the collected data for various purposes:
          ul
            li To provide and maintain our Service
            li To notify you about changes to our Service
            li To allow you to participate in interactive features of our Service when you choose to do so
            li To provide customer support
            li To gather analysis or valuable information so that we can improve our Service
            li To monitor the usage of our Service
            li To detect, prevent and address technical issues
          h4 Legal Basis for Processing Personal Data under the General Data Protection Regulation (GDPR)
          p If you are from the European Economic Area (EEA), All Turtles Corporation legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Data we collect and the specific context in which we collect it.
          p All Turtles Corporation may process your Personal Data because:
          ul
            li We need to perform a contract with you
            li You have given us permission to do so
            li The processing is in our legitimate interests and it is not overridden by your rights
            li For payment processing purposes
            li To comply with the law
          h4 Retention of Data
          p All Turtles Corporation will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to effectuate the Services, comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes and enforce our legal agreements and policies.
          p All Turtles Corporation will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to improve and strengthen the security and/or to improve the functionality of our Service, or we are legally obligated to retain this data for longer periods.
          h4 Transfer of Data
          p Your information, including Personal Data, may be transferred to - and maintained on - computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.
          p If you are located outside United States and choose to provide information to us, please note that we transfer the data, including Personal Data, to United States and process it there.
          p Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.
          p All Turtles Corporation will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organisation or a country unless there are adequate controls in place including the security of your data and other personal information.
          h4 Disclosure of Data
          BoldDiv Business Transaction
          p If All Turtles Corporation is involved in a merger, acquisition or asset sale, your Personal Data may be transferred. We will provide notice before your Personal Data is transferred and becomes subject to a different Privacy Policy.
          BoldDiv Disclosure for Law Enforcement
          p Under certain circumstances, All Turtles Corporation may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency, subpoena, etc). You will be notified of the request, and All Turtles Corporation will try to limit the amount and nature of the information disclosed to that of a “need to know” basis to the best of our ability. Once notified, you are also able to work with the requisite authorities to procure an injunction at your own cost and discretion.
          BoldDiv Legal Requirements
          p All Turtles Corporation may disclose your Personal Data in the good faith belief that such action is necessary to:
          ul
            li To comply with a legal obligation
            li To protect and defend the rights or property of All Turtles Corporation
            li To prevent or investigate possible wrongdoing in connection with the Service
            li To protect the personal safety of users of the Service or the public
            li To protect against legal liability
          h4 Security of Data
          p The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
          h4 Our Policy on "Do Not Track" Signals under the California Online Protection Act (CalOPPA)
          p We do not support Do Not Track ("DNT"). Do Not Track is a preference you can set in your web browser to inform websites that you do not want to be tracked.
          p You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.
          h4 Your Data Protection Rights under the General Data Protection Regulation (GDPR)
          p If you are a resident of the European Economic Area (EEA), you have certain data protection rights. All Turtles Corporation aims to take reasonable steps to allow you to correct, amend, delete or limit the use of your Personal Data.
          p If you wish to be informed about what Personal Data we hold about you and if you want it to be removed from our systems, please contact us.
          p In certain circumstances, you have the following data protection rights:
          p
            BoldSpan The right to access, update or delete the information we have on you.
            |  Whenever made possible, you can access, update or request deletion of your Personal Data directly within your account settings section. If you are unable to perform these actions yourself, please contact us to assist you.
          p
            BoldSpan The right of rectification.
            |  You have the right to have your information rectified if that information is inaccurate or incomplete.
          p
            BoldSpan The right to object.
            |  You have the right to object to our processing of your Personal Data.
          p
            BoldSpan The right of restriction.
            |  You have the right to request that we restrict the processing of your personal information.
          p
            BoldSpan The right to data portability.
            |  You have the right to be provided with a copy of the information we have on you in a structured, machine-readable and commonly used format.
          p
            BoldSpan The right to withdraw consent.
            |  You also have the right to withdraw your consent at any time where All Turtles Corporation relied on your consent to process your personal information.
          p Please note that we may ask you to verify your identity before responding to such requests.
          p You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area (EEA).
          h4 Service Providers
          p We may employ third party companies and individuals to facilitate our Service ("Service Providers"), provide the Service on our behalf, perform Service-related services or assist us in analysing how our Service is used.
          p These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.
          h4 Analytics
          p We may use third-party Service Providers to monitor and analyse the use of our Service. These include:
          BoldDiv Google Analytics

          p Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network.
          p
            | You may opt-out of certain Google Analytics features through your mobile device settings, such as your device advertising settings or by following the instructions provided by Google in their Privacy Policy, located here:
            a(href="https://policies.google.com/privacy?hl=en")  https://policies.google.com/privacy?hl=en

          BoldDiv Amplitude Analytics
          p
            | Amplitude Analytics is an analytics software tool for web and mobile that tracks user engagement and behavior, and real-time insight to product releases. Amplitude may use the collected data to gain insights and to refine its own products. For more information as provided in Amplitude’s privacy policy, it is located here:
            a(href="https://amplitude.com/privacy")  https://amplitude.com/privacy
          BoldDiv AppsFlyer

          p
            | AppsFlyer is a mobile application tracking and attribution analytics platform that helps developers, brands and advertising agencies track and optimize their users' acquisition funnel and engagement, and marketing analytics. AppsFlyer may use the collected data to gain insights and to refine its own products. For more information as provided in AppFlyer’s privacy policy, it is located here:
            a(href="https://www.appsflyer.com/privacy-policy/")  https://www.appsflyer.com/privacy-policy/

          BoldDiv Payments
          p We may provide paid products and/or services within the Service. In that case, we use third-party services for payment processing (e.g. payment processors).
          p We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.
          p The payment processors we work with are:
          BoldDiv Apple Store In-App Payments
          p
            | Their Privacy Policy can be viewed at
            a(href="https://www.apple.com/legal/privacy/en-ww/")  https://www.apple.com/legal/privacy/en-ww/
          BoldDiv Google Play In-App Payments
          p
            | Their Privacy Policy can be viewed at
            a(href="https://www.google.com/policies/privacy/")  https://www.google.com/policies/privacy/
          BoldDiv Stripe
          p
            | Their Privacy Policy can be viewed at
            a(href="https://stripe.com/us/privacy")  https://stripe.com/us/privacy
          BoldDiv PayPal / Braintree
          p
            | Their Privacy Policy can be viewed at
            a(href="https://www.paypal.com/webapps/mpp/ua/privacy-full")  https://www.paypal.com/webapps/mpp/ua/privacy-full
          h4 Links to Other Sites
          p Our Service may contain links to other sites that are not operated by us. If you click a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.
          p We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.
          h4 Children's Privacy
          p Our Service does not address anyone under the age of 18 ("Children").
          p We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.
          h4 Changes to This Privacy Policy
          p We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
          p We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.
          p You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.
          h4 Contact Us
          p If you have any questions about this Privacy Policy, please contact us:
          p
            | By email:
            a(href="mailto:hello@all-turtles.com")  hello@all-turtles.com
    `
  }
}
