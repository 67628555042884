import React, { Component } from 'react'

import * as S from './styles'
import { TextWithSource, HelpReduce, PauseReflect } from 'app/components'

const sources = {
  monetize: {
    author: 'Pew Research',
    title: 'Almost seven-in-ten people in the U.S. have news fatigue, more among Republicans',
    url: 'https://www.pewresearch.org/fact-tank/2018/06/05/almost-seven-in-ten-americans-have-news-fatigue-more-among-republicans/',
    quote: 'A sizable portion of people in the U.S. are feeling overwhelmed by the amount of news there is, though the sentiment is more common on the right side of the political spectrum, according to a Pew Research Center survey conducted from Feb. 22 to March 4, 2018.'
  },
  today: {
    author: 'People Press',
    title: 'More Now Say It’s ‘Stressful’ to Discuss Politics With People They Disagree With',
    url: 'https://www.people-press.org/2018/11/05/more-now-say-its-stressful-to-discuss-politics-with-people-they-disagree-with/',
    quote: 'A majority of people in the U.S. (63%) say that when they talk about politics with people they disagree with, they usually find they have “less in common” politically than they thought previously. Fewer than a third of people in the U.S. (31%) say they find they have more in common with people they disagree with politically.'
  },
  engagement: {
    author: 'Tobias Rose-Stockwell',
    title: 'This Is How Your Fear and Outrage Are Being Sold for Profit',
    url: 'https://medium.com/@tobiasrose/the-enemy-in-our-feeds-e86511488de',
    quote: 'Many news organizations have adopted a traffic-at-all-costs mentality, pushing for more engagement at the expense of what we would traditionally call editorial accuracy.'
  },
  average: {
    author: 'Recode.net',
    title: 'People consumed more media than ever last year—but growth is slowing',
    url: 'https://www.recode.net/2017/5/30/15712660/media-consumption-zenith-mobile-internet-tv ',
    quote: 'North American media consumption is expected to increase by 1.8 percent this year to 612.4 minutes a day, compared with 601.5 minutes last year. '
  },
  negativity: {
    author: 'Psychology Today',
    title: '4 Ways to Detox from Negative Media',
    url: 'https://www.psychologytoday.com/us/blog/urban-survival/201503/4-ways-detox-negative-media',
    quote: 'What we do know is that our brains are naturally built to weigh and respond to negative information much more so than positive information.This concept has been termed the negativity bias and is wide-ranging, from our decision-making to our perception of ourselves and others.'
  }
}

export default class WhySift extends Component {
  render () {
    return <S.WhySiftPage>
      <S.Hero>
        <S.WhySiftBg />
        <S.Fade />
        <S.Title>Why Sift?</S.Title>
      </S.Hero>
      <S.ContentWrap>
        <S.StyledBreakerWave />

        <S.MaxWidth>
          <S.Section>
            <S.SectionTitle>
              <S.StyledH4>News anxiety: </S.StyledH4><S.Italic>We’re wired for it</S.Italic>
            </S.SectionTitle>
            <S.P>
              <TextWithSource source={sources.monetize}>More than two-thirds of people in the U.S. feel overwhelmed by today’s 24-hour news cycle.</TextWithSource>
            </S.P>

            <S.P>So why do we keep coming back for more?</S.P>
            <S.P>
              <S.Span>Our brains are wired that way—it’s called </S.Span>
              <TextWithSource source={sources.negativity} extraPadding>negativity bias.</TextWithSource>
              <S.Span> Negativity bias helped prehistoric humans avoid danger.</S.Span>
            </S.P>
            <S.P>Today, it’s why we’re so drawn to bad news, even though it stresses us out. And because negativity captures our attention, it’s easy to monetize.</S.P>
          </S.Section>
          <S.Section>
            <S.SectionTitle><S.StyledH4>Divided we stand</S.StyledH4></S.SectionTitle>
            <S.P>Our nation’s political polarization isn’t helping matters.</S.P>
            <S.P>
              <TextWithSource source={sources.today}>Today, 53% of people in the U.S. say they find it “stressful and frustrating” to have political conversations with people they don’t agree with.</TextWithSource>
            </S.P>
            <S.P>Combined with the human tendency to look for evidence supporting our existing beliefs (known as "confirmation bias"), this means we're less likely to seek out points of view that challenge our assumptions.</S.P>
          </S.Section>
          <S.Section>
            <S.SectionTitle><S.StyledH4>An information explosion</S.StyledH4></S.SectionTitle>
            <S.P>Thanks to the Internet, the average person is exposed to a volume of information never before experienced in human history.</S.P>
            <S.P><TextWithSource source={sources.average}>One study estimates that people in North America consume an average 10 hours of media each day.</TextWithSource></S.P>
            <S.P>
              <S.Span>We’re nothing if not tuned in, and </S.Span>
              <TextWithSource source={sources.engagement}>engagement-based revenue models perpetuate news anxiety and addictive media-consumption behavior by rewarding the stories that make us feel extreme emotions.</TextWithSource>
            </S.P>
          </S.Section>
        </S.MaxWidth>
      </S.ContentWrap>
      <PauseReflect />
      <HelpReduce />
    </S.WhySiftPage>
  }
}
