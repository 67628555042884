import React, { Component } from 'react'
import { Parallax } from 'react-scroll-parallax'

import * as S from './styles'

export default class TheProblem extends Component {
  render () {
    return <S.TheProblemComponent>
      <S.TopBlueWave />
      <S.ContentWrap>
        <S.SuperTitle>The Problem</S.SuperTitle>
        <S.Title>
          <S.StyledH2>Our nation is divided, </S.StyledH2>
          <S.ItalicH2>and it's stressing us out</S.ItalicH2>
        </S.Title>
        <S.BodyCopy>
          <S.Iceberg1Wrap>
            <Parallax x={[10, -10]}>
              <S.Iceberg1 />
            </Parallax>
          </S.Iceberg1Wrap>
          <S.Iceberg2Wrap>
            <Parallax x={[-10, 10]}>
              <S.Iceberg2 />
            </Parallax>
          </S.Iceberg2Wrap>
          <S.Paragraph>
            According to a <S.StyledA href='https://www.pewresearch.org/fact-tank/2018/06/05/almost-seven-in-ten-americans-have-news-fatigue-more-among-republicans/' target='_blank'>2018 Pew study</S.StyledA>, nearly two-thirds of people in the U.S. feel stressed by the news.
          </S.Paragraph>
          <S.Paragraph>
            When news anxiety leads to feelings of helplessness and hopelessness, our civic engagement and democracy inevitably suffer.
          </S.Paragraph>
          <S.Paragraph>
            Tough topics become less overwhelming when we grant ourselves the time, space, and attention to pursue deeper understanding.
          </S.Paragraph>
        </S.BodyCopy>
      </S.ContentWrap>
    </S.TheProblemComponent>
  }
}
