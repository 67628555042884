import React, { Component } from 'react'

import * as S from './styles'

import solutionItems from './solutionItems'
import SolutionCard from './SolutionCard/SolutionCard'

export default class Solution extends Component {
  render () {
    return <S.SolutionComponent>
      <S.StyledBreakerWave />
      <S.StyledH5>How Sift Helps</S.StyledH5>
      <S.StyledH2>Introducing</S.StyledH2>
      <S.ItalicH2>News Therapy</S.ItalicH2>
      <S.SolutionSections>
        <S.Line />
        { Object.values(solutionItems).map((item, index) => <SolutionCard data={item} number={index + 1} key={item.title} />) }
      </S.SolutionSections>
    </S.SolutionComponent>
  }
}
