import { graph1, graph2, graph3 } from 'app/assets/images'

export default {
  slide1: {
    title: 'Chinese Exclusion Act',
    description: 'For nearly 100 years, U.S. laws restricted immigration from Asia, culminating in the Chinese Exclusion Act. Restrictions started to ease during WWII and the Cold War.',
    year: '1882',
    imageUrl: graph1
  },
  slide2: {
    title: 'Quota system',
    description: 'The National Origins Act of 1924 set a 2% annual immigration cap based on the total U.S. population of foreign-born people by nationality.',
    year: '1924',
    imageUrl: graph2
  },
  slide3: {
    title: 'Immigration and Nationality Act',
    description: 'The 1965 law repealed the quota system and, for the first time, stopped restricting immigration from non-European countries.',
    year: '1965',
    imageUrl: graph3
  }
}
