import React, { Component } from 'react'

import * as S from './styles'

export default class Whisp extends Component {
  render () {
    const { orientation, size, type } = this.props
    return <S.WhispComponent orientation={orientation} size={size} type={type} {...this.props}>
      <S.Whisp orientation={orientation} size={size} />
    </S.WhispComponent>
  }
}
