import React, { PureComponent } from 'react'
import { Carousel } from 'app/ui-kit'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import * as S from './styles'
import { topicsList } from 'app/data'
import { setTopicsCarouselIndex } from 'app/store/app/actions'
import Analytics from 'app/analytics'

const mapStateToProps = (state) => {
  const { topicsCarouselIndex } = state.app
  return { topicsCarouselIndex }
}

@withRouter
@connect(mapStateToProps, { setTopicsCarouselIndex })
export default class Phone extends PureComponent {
  renderSlide = (data) => {
    return <S.Slide key={data.title}>
      <S.ImageWrap>
        <S.Darken />
        <S.CarouselImage src={data.image} />
      </S.ImageWrap>
      <S.SlideText>
        <S.Title>{data.title}</S.Title>
      </S.SlideText>
      <S.SlideSubText>
        <S.SlideSubTitle>{data.subtitle}</S.SlideSubTitle>
        <S.SlideDescription>{data.description}</S.SlideDescription>
      </S.SlideSubText>
    </S.Slide>
  }

  onChange = (data) => {
    this.carouselIndex = data
    this.props.setTopicsCarouselIndex(data)

    Analytics.event({
      eventType: 'TOPICS CAROUSEL',
      pathname: this.props.location.pathname,
      props: {
        type: 'always showing',
        selectedIndex: data
      }
    })
  }

  render () {
    return <S.PhoneComponent>
      <S.Speaker />
      <S.CarouselWrap>
        <Carousel
          options={{ prevNextButtons: false, pageDots: false, wrapAround: true }}
          className='flickity-fade'
          onChange={this.onChange}
          selectedIndex={this.props.topicsCarouselIndex}
          withPagination
          paginationType='phone'
        >
          { Object.values(topicsList).map(this.renderSlide)}
        </Carousel>
      </S.CarouselWrap>
    </S.PhoneComponent>
  }
}
