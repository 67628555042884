import React from 'react'
import styled, { css } from 'styled-components'
import { Colors, Breakpoints, Sizes, Type } from 'app/styles'
import { Logo, Menu, X } from 'app/ui-kit/Icons'
import { Link } from 'react-router-dom'

export const BasicNavComponent = styled.div`
  display: flex;
  justify-content: center;
  position: fixed;
  top: -70px;
  left: 0;
  width: 100%;
  height: ${Sizes.mobileNav};
  background-color: transparent;
  z-index: 999;
  opacity: 0;
  transition: all .25s ease-in-out;
  width: 100vw;

  @media(max-width: ${Breakpoints.main}){
    top: 0;
    opacity: 1;
  }

  ${props => props.scrolledNavOpen && css`
    top: 0;
    opacity: 1;
  `}
`

export const NavWrapper = styled.div`
  max-width: ${Sizes.container};
  background-color: ${Colors.offWhite};
  display: flex;
  flex-grow: 1;
  justify-content: center;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, .1);
  padding: 0 56px;
  box-sizing: border-box;
  transition: box-shadow .25s ease-in-out, background-color .25s ease-in-out;

  @media(max-width: ${Breakpoints.main}) {
    box-shadow: none;
    padding: 0 ${Sizes.xPaddingMain};
    background-color: transparent;

    ${props => props.isScrolled && css`
      background-color: ${props.theme.desktop.intermediateBackgroundColor ? props.theme.desktop.intermediateBackgroundColor : props.theme.mobile.backgroundColor};
      box-shadow: 0 4px 6px 0 rgba(0, 0, 0, .1);
    `}

    ${props => props.mobileNavOpen && css`
      background-color: ${Colors.darkBlue};
      box-shadow: 0 4px 6px 0 rgba(0, 0, 0, .1);
    `}
  }

  @media(max-width: ${Breakpoints.smallest}) {
    padding: 0 ${Sizes.xPaddingSmallest};

    ${props => props.isScrolled && css`
      background-color: ${props.theme.mobile.backgroundColor};
    `}

    ${props => props.mobileNavOpen && css`
      background-color: ${Colors.darkBlue};
    `}
  }
`

export const MaxWidth = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: ${Sizes.maxWidth};
  flex-grow: 1;
`

export const LogoWrap = styled(Link)`
  display: flex;
  align-items: center;
`
export const LogoLink = styled.a`

`
export const StyledLogo = styled(({ mobileNavOpen, theme, ...rest }) => <Logo {...rest} />)`
  width: 62px;
  height: 30px;

  @media(max-width: ${Breakpoints.main}) {
    path {
      fill: ${props => {
    return props.theme.desktop.intermediateLogoColor ? props.theme.desktop.intermediateLogoColor : props.theme.mobile.logoColor
  }};
    }

    ${props => props.mobileNavOpen && css`
      path {
        fill: ${Colors.skyBlue};
      }
    `}
  }

  @media(max-width: ${Breakpoints.smallest}) {
    path {
      fill: ${props => props.theme.mobile.logoColor};
    }

    ${props => props.mobileNavOpen && css`
      path {
        fill: ${Colors.skyBlue};
      }
    `}
  }
`

export const NavItems = styled.div`
  display: flex;
  align-items: center;

  @media(max-width: ${Breakpoints.main}) {
    display: none;
  }
`

export const MobileNavController = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
`

export const MobileNav = styled.div`
  display: none;
  position: relative;
  justify-content: center;
  align-items: center;

  @media(max-width: ${Breakpoints.main}) {
    display: flex;
  }
`

export const MenuIconWrap = styled.div`
  opacity: 0;
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  pointer-events: none;

  ${props => props.isOpen && css`
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  `}
`

export const StyledMenu = styled(Menu)`
  margin-top: 4px;

  line {
    transition: stroke .3s ease;
  }

  &:hover {
    line {
      stroke: ${Colors.peachExtraLight}
    }
  }

  @media(max-width: ${Breakpoints.main}){
    line {
      stroke: ${props => props.theme.desktop.intermediateNavColor ? props.theme.desktop.intermediateNavColor : props.theme.mobile.navColor};
    }
  }

  @media(max-width: ${Breakpoints.main}){
    line {
      stroke: ${props => props.theme.mobile.navColor};
    }
  }
`
export const StyledX = styled(X)`
  line {
    transition: stroke .3s ease;
  }

  line {
    stroke: ${Colors.peachLight};
  }

  &:hover {
    line {
      stroke: ${Colors.peachExtraLight};
    }
  }
`
export const MobileNavExpanded = styled.div`
  background-color: ${Colors.darkBlue};
  min-height: 100vh;
  width: 100vw;
  opacity: 0;
  visibility: hidden;
  transition: all .25s ease-in-out;
  position: fixed;
  top: ${Sizes.mobileNav};
  left: 0;
  z-index: 4;
  border-top: 1px solid ${Colors.violetDark};

  ${props => props.isOpen === 'true' && css`
    opacity: 1;
    visibility: visible;
  `}
`
export const MobileNavItemText = styled.div`
  border-bottom: 1px solid ${Colors.violetDark};
  margin-left: ${Sizes.xPaddingMain};
  width: inherit;
  height: inherit;
  display: flex;
  align-items: center;

  @media(max-width: ${Breakpoints.smallest}){
    margin-left: ${Sizes.xPaddingSmallest};
  }
`
export const MobileNavItems = styled.div``
const mobileNavShared = css`
  height: 90px;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;

  transition: background-color 90ms ease;
  color: ${Colors.skyBlue};
  ${Type.navItem};

  &:hover {
    text-decoration: none;
    background-color: ${Colors.violetDark};
    color: ${Colors.skyBlue};
  }
`
export const MobileNavItem = styled(Link)`
  ${mobileNavShared};
`
export const MobileNavItemGetInTouch = styled.div`
  ${mobileNavShared};
`
export const NavItem = styled(Link)`
  margin-right: 48px;
  cursor: pointer;
  transition: all .2s ease-out;
  display: flex;
  align-items: center;
  color: ${Colors.peach};
  ${Type.navItem};

  &:hover {
    color: ${Colors.peachMedium};
  }

  &:last-child {
    margin-right: 0;
  }
`
