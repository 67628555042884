import styled from 'styled-components'

import { Breakpoints } from 'app/styles'
import * as A from './animations'

const animationLength = 9
const easing = 'ease-in-out'

export const FloatingNewsMobileComponent = styled.div`
  display: none;
  margin-top: 20px;

  @media(max-width: ${Breakpoints.smallest}){
    display: block;
    position: relative;
    height: 217px;
    width: 100vw;
  }
`

export const FloatingGirl = styled.img`
  width: 115px;
  height: 152px;
  position: absolute;
  left: 50%;
  margin-left: -57px;
  z-index: 2;
  animation: ${A.floatingGirlAnimation} ${animationLength}s ${easing} infinite;
`

export const PersonShadow = styled.img`
  width: 115px;
  height: 13px;
  position: absolute;
  left: 50%;
  margin-left: -57px;
  top: 180px;
  animation: ${A.personShadowAnimation} ${animationLength}s ${easing} infinite;
`

export const MobileBackPapers = styled.img`
  position: absolute;
  width: 58px;
  height: 65px;
  left: 50%;
  top: -12px;
  margin-left: 20px;
  z-index: 1;
  animation: ${A.newsAnimation2} ${animationLength - 2}s ${easing} infinite;
`

export const MobileFrontPapers = styled.img`
  position: absolute;
  width: 57px;
  height: 64px;
  left: 50%;
  top: 102px;
  margin-left: -90px;
  z-index: 3;
  animation: ${A.newsAnimation3} ${animationLength - 1}s ${easing} infinite;
`

export const MobileLeftPapers = styled.img`
  position: absolute;
  width: 34px;
  height: 59px;
  left: 50%;
  top: 0;
  margin-left: -137px;
  animation: ${A.newsAnimation2} ${animationLength}s ${easing} infinite;
`

export const MobileLeftSmallPapers = styled.img`
  position: absolute;
  width: 82px;
  height: 76px;
  left: 50%;
  top: 25px;
  margin-left: -140px;
  animation: ${A.newsAnimation1} ${animationLength}s ${easing} infinite;
`

export const MobileRightSmallPapers = styled.img`
  position: absolute;
  width: 39px;
  height: 23px;
  left: 50%;
  top: 50px;
  margin-left: 98px;
  animation: ${A.newsAnimation1} ${animationLength - 2}s ${easing} infinite;
`

export const MobileYellowPage = styled.img`
  position: absolute;
  width: 46px;
  height: 56px;
  left: 50%;
  top: 115px;
  margin-left: 93px;
  animation: ${A.newsAnimation3} ${animationLength + 1}s ${easing} infinite;
`
