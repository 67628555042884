import styled, { css } from 'styled-components'

import { Breakpoints, Type, Colors, Templates } from 'app/styles'
import { Button } from 'app/ui-kit'
import { Carot } from 'app/ui-kit/Icons'

export const SourceComponent = styled.div`
  ${Templates.solutionBox};
  position: relative;
  padding: 40px;

  @media(max-width: ${Breakpoints.solutionComponent3}){
    height: 340px;
  }
`

export const Quote = styled.div`
  ${Type.questionBodyBig};
  color: ${Colors.periwinkle};
  opacity: 0;
  transition: transform .5s ease-in-out, margin-top .5s ease, opacity .5s ease-in-out, height .5s ease-in-out;
  height: 0;
  overflow: hidden;
  box-sizing: border-box;
  margin-top: 0;

  ${props => props.visible && css`
    opacity: 1;
    transform: translate3d(0, 0, 0);
    height: 180px;
    margin-top: 40px;
  `}

  @media(max-width: ${Breakpoints.smallest}){
    padding: 0;
  }
`
export const SourceToggle = styled(Button)`
  z-index: 2;
  position: absolute;
  bottom: 55px;

  @media(max-width: ${Breakpoints.solutionComponent3}){
    bottom: 45px;
  }
`
export const StyledCarot = styled(Carot)`
  margin-left: 20px;

  ${props => props.expanded && css`
    transform: scaleY(-1);
  `}
`

export const SourceWrap = styled.div`
  opacity: 1;
  color: ${Colors.periwinkle};
  transition: transform .5s ease-in-out, opacity .2s ease-in-out, height .5s ease-in-out;
  height: 0;
  overflow: hidden;
  box-sizing: border-box;

  ${props => props.visible && css`
    opacity: 1;
    transform: translate3d(0, 30px, 0);
    height: 270px;
  `}

  @media(max-width: ${Breakpoints.smallest}){
    padding: 0;
  }
`
export const Author = styled.div`
  font-size: 18px;
  font-weight: 600;

  @media(max-width: ${Breakpoints.solutionComponent3}){
    font-size: 15px;
    line-height: normal;
  }
`
export const Title = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 13px;

  @media(max-width: ${Breakpoints.solutionComponent3}){
    font-size: 18px;
    line-height: 26px;
  }
`
export const SourceQuote = styled.div`
  font-size: 17px;
  font-weight: 600;
  line-height: 22px;
  padding-left: 13px;
  border-left: 1px solid ${Colors.periwinkle};

  @media(max-width: ${Breakpoints.solutionComponent3}){
    font-size: 14px;
    line-height: normal;
  }
`
