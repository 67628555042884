import React from 'react'

import { Colors } from 'app/styles'

export default (props) =>
  <svg {...props} width='94' height='45' viewBox='0 0 94 45' fill='none'>
    <path d='M19.2626 18.9667C14.8043 17.4618 10.9249 15.7254 10.9249 11.5001C10.9249 8.43238 13.3567 6.34866 16.8308 6.34866C22.4472 6.34866 25.8633 10.2846 28.9321 16.7094H29.6269L29.5111 6.00137H28.7005C28.6426 7.15899 27.6582 7.67992 26.2107 7.39052C23.7789 6.92747 20.8839 5.13315 16.8308 5.13315C7.39297 5.13315 0.734375 9.87941 0.734375 17.4618C0.734375 25.2179 6.00335 28.5171 11.3302 30.3693C16.8887 32.3373 22.5051 32.6267 22.5051 37.7781C22.5051 41.4246 20.0732 43.4505 16.0202 43.4505C10.6354 43.4505 4.61373 40.0355 2.12399 31.6427H1.42918L1.71869 44.7238H2.2977C3.45571 43.4505 4.20842 43.2189 6.06125 43.3347C8.49308 43.5083 11.5039 44.7238 16.0202 44.7238C24.6474 44.7238 32.2903 39.9197 32.2903 32.0479C32.3482 22.5554 25.2264 20.9926 19.2626 18.9667Z' fill={Colors.darkBlue} />
    <path d='M40.7438 17.9249C43.6388 17.9249 45.8969 15.8412 45.8969 13.1207C45.8969 10.4003 43.6967 8.31662 40.7438 8.31662C37.8487 8.31662 35.7064 10.4003 35.7064 13.1207C35.7064 15.7833 37.8487 17.9249 40.7438 17.9249Z' fill={Colors.darkBlue} />
    <path d='M92.5651 19.1403L93.0283 17.8091H84.6906V7.62198H83.7642C83.1852 13.8731 78.9584 17.8091 70.5049 17.8091H65.2938V5.36462C65.2938 2.93361 66.2202 1.66023 68.2468 1.66023C70.2733 1.66023 71.1997 2.47056 71.1997 3.45454C71.1997 4.43852 69.289 5.24886 69.289 7.67987C69.289 10.2845 71.1997 12.5419 73.9789 12.5419C76.7582 12.5419 78.6689 10.4003 78.6689 7.67987C78.6689 3.68607 74.4421 0.734131 68.2468 0.734131C58.2878 0.734131 52.3819 5.88555 52.3819 14.6835V38.1253C52.3819 38.9357 52.3819 39.5724 52.3819 40.1512C52.324 41.714 51.0502 42.9874 49.4869 42.9874C47.9236 42.9874 46.7077 41.7719 46.5919 40.2669C46.5919 39.6881 46.5919 39.0514 46.5919 38.1832V19.777H46.1866C43.3494 22.4974 39.0648 23.1341 35.2433 22.9605V38.0675C35.2433 41.7718 35.2433 42.2928 32.9273 44.0871V44.2029H69.3469V44.145C66.3939 42.5822 65.3517 41.8297 65.3517 38.1253V19.2561L67.8994 19.314C71.605 19.314 72.8788 21.5135 72.9367 24.176V33.9579C72.8788 41.0194 76.2371 44.7817 81.9114 44.7817C86.8329 44.7817 89.8438 42.3507 91.5808 39.2251L91.0018 38.762C90.1912 39.8039 89.2068 40.3248 87.9909 40.3248C85.8486 40.3248 84.8064 38.8199 84.8064 36.3889V19.8349L92.5651 19.1403Z' fill={Colors.darkBlue} />
  </svg>
