import React, { Component } from 'react'

import * as S from './styles'
import { Whisp } from 'app/components'

export default class SolutionCard extends Component {
  render () {
    const { data, number } = this.props
    return <S.SolutionSection>
      { data.whisps && data.whisps.map((item, index) => <Whisp orientation={item.orientation} type={item.type} size={item.size} key={index} />) }
      <S.SolutionPanel>
        <S.NumberCircle>{number}</S.NumberCircle>
        <S.SolutionText>
          <S.StyledH3>{data.title}</S.StyledH3>
          <S.SolutionDescription>{data.description}</S.SolutionDescription>
        </S.SolutionText>
      </S.SolutionPanel>
      <S.SolutionPanelComponent>
        <data.component />
        <S.Shadow />
      </S.SolutionPanelComponent>
    </S.SolutionSection>
  }
}
