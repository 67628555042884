import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import * as S from './styles'
import Analytics from 'app/analytics'

const source = {
  author: 'Small Arms Survey',
  title: 'More than one billion firearms in the world',
  quote: 'Even after adjusting for population, it is clear that the United States far outnumbers other countries. The United States has 4% of the world’s population, but its civilians hold almost 40% of the world’s firearms.'
}

@withRouter
export default class Source extends Component {
  state = { expanded: false }

  toggleSource = () => {
    const { expanded } = this.state
    this.setState({ expanded: !expanded })

    Analytics.event({
      eventType: 'WIDGET INTERACTION',
      pathname: this.props.location.pathname,
      props: {
        widgetName: 'Source',
        slug: 'expanded',
        value: !expanded
      }
    })
  }

  render () {
    const { expanded } = this.state

    return <S.SourceComponent>
      <S.Quote visible={!expanded}>It’s estimated that 40% of the world’s civilian firearms are owned by people in the U.S., which has 4% of the world’s population.</S.Quote>
      <S.SourceWrap visible={expanded}>
        <S.Author>{source.author}</S.Author>
        <S.Title>{source.title}</S.Title>
        <S.SourceQuote>{source.quote}</S.SourceQuote>
      </S.SourceWrap>
      <S.SourceToggle type='condensed' onClick={this.toggleSource}>
        1 Source
        <S.StyledCarot expanded={expanded ? true : undefined} />
      </S.SourceToggle>
    </S.SourceComponent>
  }
}
