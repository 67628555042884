import styled, { keyframes } from 'styled-components'

import { Breakpoints } from 'app/styles'

const animationLength = 9

const floatingGirlAnimation = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, 18px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
`

const newsTodayAnimation = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, -10px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
`

const newsAnimation = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, 18px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
`

const newsPaperAnimation = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, -22px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
`

export const FloatingNewsComponent = styled.div`
  position: absolute;
  z-index: 3;
  right: -340px;

  @media(max-width: ${Breakpoints.floatingNewsAdjust}) {
    right: -385px;
  }

  @media(max-width: ${Breakpoints.main}) {
    right: -128px;
  }

  @media(max-width: ${Breakpoints.smallest}) {
    display: none;
  }
`
export const Storm = styled.img`
  width: 1155px;
  height: 1073px;

  @media(max-width: ${Breakpoints.main}) {
    width: 679px;
    height: 570px;
  }
`
export const FloatingGirl = styled.div`
   width: 312px;
   height: 391px;
   position: absolute;
   background-image: url(${props => props.imageUrl});
   background-size: cover;
   background-repeat: no-repeat;
   left: 410px;
   top: -40px;
   animation: ${floatingGirlAnimation} ${animationLength}s ease-in-out infinite;

   @media(max-width: ${Breakpoints.main}) {
    width: 183px;
    height: 226px;
    left: 243px;
  }
`
export const NewsToday = styled.img`
  width: 161px;
  height: 176px;
  position: absolute;
  left: 707px;
  top: 96px;
  animation: ${newsTodayAnimation} ${animationLength + 3}s ease-in-out infinite;

  @media(max-width: ${Breakpoints.main}) {
    width: 95px;
    height: 102px;
    left: 415px;
    top: 60px;
  }
`
export const News = styled.img`
  width: 171px;
  height: 253px;
  position: absolute;
  left: 95px;
  top: 430px;
  animation: ${newsAnimation} ${animationLength + 1}s ease-in-out infinite;

  @media(max-width: ${Breakpoints.main}) {
    width: 101px;
    height: 146px;
    left: 55px;
    top: 246px;
  }
`
export const NewsPaper = styled.img`
  width: 210px;
  height: 260px;
  position: absolute;
  left: 653px;
  top: 555px;
  animation: ${newsPaperAnimation} ${animationLength}s ease-in-out infinite;

  @media(max-width: ${Breakpoints.main}) {
    width: 123px;
    height: 150px;
    left: 381px;
    top: 305px;
  }
`
