import { GraphCarousel, Source, Quiz, FillIn, Reflection } from 'app/components'

export default {
  backstory: {
    title: 'We shed light on the backstory',
    description: 'How did we get here? Explore the history of today’s issues.',
    component: GraphCarousel,
    whisps: [{
      orientation: 'left',
      size: 'double',
      type: 'desktop'
    }, {
      orientation: 'right',
      size: 'single',
      type: 'desktop'
    }, {
      orientation: 'left',
      size: 'double',
      type: 'mobile'
    }]
  },
  quiz: {
    title: 'We help you engage more of your brain',
    description: 'Our interactive features encourage critical thinking and promote calm understanding.',
    component: Quiz,
    whisps: [{
      orientation: 'right',
      size: 'single',
      type: 'mobile'
    }]
  },
  sources: {
    title: 'We make it easy to delve into sources',
    description: 'Dig deeper into the source material behind our content.',
    component: Source,
    whisps: [{
      orientation: 'left',
      size: 'single',
      type: 'mobile'
    }]
  },
  fillIn: {
    title: 'We use calming, accessible language',
    description: 'Sift steers clear of inflammatory word choice.',
    component: FillIn,
    whisps: [{
      orientation: 'right',
      size: 'double',
      type: 'desktop'
    }, {
      orientation: 'left',
      size: 'single',
      type: 'desktop'
    }, {
      orientation: 'right',
      size: 'single',
      type: 'mobile'
    }]
  },
  reflection: {
    title: 'We make room for reflection',
    description: 'Consider what you know and how our stories make you feel.',
    component: Reflection,
    whisps: [{
      orientation: 'left',
      size: 'double',
      type: 'mobile'
    }]
  }
}
