import styled from 'styled-components'

import { Breakpoints, Colors, Type } from 'app/styles'

export const TopicsCarouselComponent = styled.div``

export const CarouselWrap = styled.div`
  width: 560px;
  margin-bottom: 66px;
  height: 218px;

  @media(max-width: ${Breakpoints.largest}){
    display: none;
  }
`
export const Slide = styled.div`
  position: relative;
  margin-right: 18px;
  border-radius: 12px;
  overflow: hidden;
  height: 218px;
`
export const SlideText = styled.div`
  position: absolute;
  margin: 25px;
  top: 0;
  left: 0;
`
export const Title = styled.div`
  ${Type.slideTitle};
  margin-bottom: 10px;
  color: ${Colors.white};
  position: relative;
  z-index: 2;
`
export const Meta = styled.div`
  display: flex;
  font-size: 12px;
  font-weight: 500;
  color: ${Colors.white};
`

export const Parts = styled.div`
  text-transform: uppercase;
  margin-right: 10px;
`

export const Length = styled.div`
  text-transform: uppercase;
`

export const CarouselImage = styled.img`
  width: 174px;
  height: auto;
`

export const Darken = styled.div`
  width: 174px;
  height: 143px;
  position: absolute;
  top: 0px;
  left: 0px;
  background-image: linear-gradient(153.21deg, rgba(119, 133, 150, 0.7) 16.61%, rgba(71, 106, 148, 0) 63.13%);
  mix-blend-mode: multiply;
  z-index: 1;

  @media(max-width: ${Breakpoints.main}){
    top: -150px;
  }
`
