import styled, { css } from 'styled-components'

import { Breakpoints, Type, Colors, Sizes } from 'app/styles'

export const PrivacyPolicyPage = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 0 100px 0;
  background-color: ${Colors.white};

  h4 {
    margin-bottom: 40px;
    margin-top: 82px;

    @media(max-width: ${Breakpoints.main}){
      margin-bottom: 30px;
      margin-top: 60px;
    }
  }

  p {
    ${Type.body};
  }

  li {
    margin-bottom: .33em;
  }

  a {
    color: ${Colors.blue};
    transition: opacity .3s ease;

    &:hover {
      opacity: 0.5;
    }

    &:active {
      opacity: 0.9;
    }
  }
`
export const MaxWidth = styled.div`
  max-width: 1020px;
  box-sizing: border-box;
  padding: 0 80px;

  @media(max-width: ${Breakpoints.main}){
    padding: 0 150px;
    max-width: 100vw;
  }

  @media(max-width: ${Breakpoints.smallest}){
    padding: 0 ${Sizes.xPaddingSmallest};
  }
`

export const PageTitle = styled.h3`
  margin-top: 140px;
  margin-bottom: 100px;

  ${props => props.theme === 'hidden' && css`
    margin-top: 45px;
    margin-bottom: -15px;
  `}
`

export const BoldSpan = styled.span`
  font-weight: bold;
  ${Type.body};
`

export const BoldDiv = styled.div`
  font-weight: bold;
  ${Type.body};
  margin-bottom: 10px;
`
