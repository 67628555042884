import React, { Component } from 'react'
import { Link, withRouter, matchPath } from 'react-router-dom'

import * as S from './styles'
import routes from '../../routes/routes'

@withRouter
export default class Faq extends Component {
  getThemesForRoute = () => {
    const { location } = this.props
    const activeRoute = routes[0].routes.find(route => matchPath(location.pathname, route))
    return { theme: activeRoute.navTheme, mobileTheme: activeRoute.mobileNavTheme }
  }

  render () {
    const { theme } = this.getThemesForRoute()

    return <S.FaqPage>
      <S.MaxWidth>
        <S.PageTitle theme={theme}>FAQ</S.PageTitle>
        <h4>How did you decide to make Sift?</h4>
        <p>We’ve spent a lot of time unpacking our own relationships with the news. At first, we wanted to get out of our own echo chambers and make sure we were exposed to diverse perspectives. We found that takes a lot of effort, and we wanted to make it easier for others.</p>
        <p>Then, as we started designing Sift, we identified the tension between wanting to stay informed and feeling overwhelmed by the news. It’s a widespread problem that affects the wellbeing a majority in the U.S. We believe there’s a way to stay informed and feel empowered at the same time. And as technologists, we feel we have a responsibility to help design a way forward.</p>
        <p>After a two-month experiment in October 2018, our user testing showed users feeling significantly less anxious and more informed after using the app. </p>

        <h4>Who makes Sift?</h4>
        <p>The Sift team includes Gabe Campodonico (co-founder) and Chris Ploeg (co-founder), Steve White (engineering), and Kelly Chen (editorial). We work with a team of contributing writers and editors, including Nithin Coca, Laura Dorwart, Hilary Fung, MJ Gimenez, Tekendra Parmar, Casey O’Brien, Anna Rosenwong, Lewis Wallace, and Rowan Walrath.</p>

        <h4>Why did you pick these topics for Series 1?</h4>
        <p>We deliberately chose topics that will continue to be big problems, such as immigration, healthcare, and climate change. By focusing less on breaking news and daily updates, Sift is able to prioritize providing a space to take a step back and see the big picture without being weighed down by incremental details (which we know are important, too).</p>

        <h4>How much does Sift cost?</h4>
        <p>It’s important to us that Sift’s business model reflect our core principles: No ads, no data targeting, and no distractions. As a news therapy app, we want to avoid the traps of a revenue model that contributes to news anxiety and the polarization of emotions about the news.</p>
        <p>That’s why we are a subscription-based model. Series 1 of Sift offers a 6-month subscription for $19.99 with a 7-day free trial. This price allows us to provide a quiet, mindful space to engage with information without distractions.</p>

        <h4>What data does Sift collect?</h4>
        <p>Sift doesn’t require an account, so we don’t collect any personal data. We do monitor how people use the app so we can make it better. However, we can’t tell who you are when you use the app or send us feedback through it.</p>
        <p>If you choose to sign up for our email list, your email is stored separately from any app usage data or feedback.</p>

        <h4>What does Sift do with my data?</h4>
        <p>If you sign up for our email list, we store your email in MailChimp. That’s the only personal data we collect, and it’s entirely optional.</p>

        <h4>Does Sift share my data with third parties?</h4>
        <p>Nope. The only personal data we have is your email address—if you choose to share it. And we don’t share that beyond Sift. Check out our <Link to='/privacy-policy' target='_blank'>privacy policy</Link> for more detail.</p>

        <h4>How are Sift and All Turtles connected?</h4>
        <p>All Turtles’ mission is to build meaningful products that solve real problems around the world. Sift is one of those products.</p>

        <h4>Does Sift use artificial intelligence, machine learning, or personalization?</h4>
        <p>Nope. Sift is designed, written, and edited by humans. Everyone sees the same content when using Sift.</p>
        <p>When All Turtles—an AI startup studio—started Sift, we thought AI would help produce a better news experience and even simplify complex topics. But we learned that AI is part of the problem. Our user research showed that users value human curation, not based on algorithms that clutter news consumption.</p>

        <h4>I have more questions. How can I get in touch?</h4>
        <p>Email us at <a href='mailto:support@siftnewstherapy.com'>support@siftnewstherapy.com</a></p>
      </S.MaxWidth>
    </S.FaqPage>
  }
}
