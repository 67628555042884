import { LightBulb, ChatPaper, Drama, Puzzle } from 'app/ui-kit/Icons'

export default {
  knowledgeBase: {
    title: 'Build a knowledge base.',
    description: 'Sift goes beyond headlines and talking points. Dig deep into historical context: What isn’t being talked about? What voices aren’t being heard and why?',
    icon: LightBulb
  },
  reframe: {
    title: 'Reframe the narrative.',
    description: 'Sift’s goal is to surface ideas that move conversations forward. For instance: What does the enduring nature of these hot-button issues mean for our society?',
    icon: ChatPaper
  },
  think: {
    title: 'Take time to think and feel.',
    description: 'Sift stories include frequent pauses for reflection or interactivity, to help you engage with issues, check your assumptions, and evaluate your emotions.',
    icon: Drama
  },
  challenge: {
    title: 'Challenge yourself.',
    description: 'Throughout each narrative, we’ve interspersed exercises that invite you to actively engage with the topics. As our editors like to say, “it’s a gym, not a spa.”',
    icon: Puzzle
  }
}
