import styled from 'styled-components'

import { Link } from 'react-router-dom'

import { Colors, Sizes, Type, Breakpoints } from 'app/styles'
import { AllTurtlesLogo, Twitter, Instagram, AllTurtlesProduct } from 'app/ui-kit/Icons'

export const FooterComponent = styled.div`
  background-color: ${Colors.royalBlue};
  width: 100%;
  align-items: center;
  padding-top: 2px;
  line-height: normal;
  position: relative;
  z-index: 2;
`
export const MaxWidth = styled.div`
  max-width: ${Sizes.maxWidth};
  display: flex;
  flex-grow: 1;

  @media(max-width: ${Breakpoints.smallest}){
    flex-direction: column;
    justify-content: flex-start
  }
`

export const ATMaxWidth = styled.div`
  max-width: ${Sizes.maxWidth};
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
`
export const AllTurtles = styled.div`
  display: flex;
  align-items: center;
`

export const StyledAllTurtlesLogo = styled(AllTurtlesLogo)`

`

export const StyledAllTurtlesProduct = styled(AllTurtlesProduct)`
`

export const Legal = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${Colors.royalBlue};
  padding: 12px 25px;
  box-sizing: border-box;

  @media(max-width: ${Breakpoints.largest}){
    width: 100vw;
  }

  @media(max-width: ${Breakpoints.smallest}){
    flex-direction: column;
    align-items: flex-start;
  }
`

export const AllTurtlesVerticalRule = styled.div`
  width: 1px;
  height: 20px;
  background-color: ${Colors.white30};
  margin: 0 12px;
`

export const VerticalRule = styled.div`
  width: 1px;
  height: 20px;
  background-color: ${Colors.white30};
  margin: 0 12px;

  @media(max-width: ${Breakpoints.smallest}) {
    display: none;
  }
`

export const FaqAndPrivacy = styled.div`
  display: flex;

  @media(max-width: ${Breakpoints.smallest}) {
    flex-direction: column;
  }
`

export const PrivacyPolicy = styled(Link)`
  ${Type.smallBodySemi};
  font-weight: 600;
  color: ${Colors.peachLight};
  font-size: 16px;

  @media(max-width: ${Breakpoints.smallest}) {
    margin-bottom: 5px;
  }

  &:hover {
    color: ${Colors.peachExtraLight};
  }
`

export const Terms = styled(Link)`
  ${Type.smallBodySemi};
  font-weight: 600;
  color: ${Colors.peachLight};
  font-size: 16px;
  margin-left: 10px;

  @media(max-width: ${Breakpoints.smallest}) {
    margin-bottom: 5px;
  }

  &:hover {
    color: ${Colors.peachExtraLight};
  }
`

export const Copyright = styled.div`
  ${Type.smallBody};
  color: ${Colors.white};
  opacity: 0.6;
  font-size: 16px;
`

export const StyledTwitter = styled(Twitter)`
  margin-right: 18px;
  cursor: pointer;

  path {
    transition: fill .175s ease-in-out;
  }

  &:hover {
    path {
      fill: ${Colors.peachExtraLight};
    }
  }
`

export const StyledInstagram = styled(Instagram)`
  width: 25px;
  height: 25px;
  cursor: pointer;

  path {
    transition: fill .2s ease-in-out;
  }

  &:hover {
    path {
      fill: ${Colors.peachExtraLight};
    }
  }
`

export const Social = styled.div`
  display: flex;
`

export const MobileSocial = styled.div`
  display: none;

  @media(max-width: ${Breakpoints.smallest}){
    display: flex;
  }
`

export const AllTurtlesAndMobileSocial = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 35px 25px;
`
