import React from 'react'

import { Colors } from 'app/styles'

export default (props) =>
  <svg {...props} width='25' height='26' viewBox='0 0 25 26' fill='none'>
    <path fill={Colors.peachLight} d='M17.7592 25.6438H7.58187C3.58607 25.6438 0.341797 22.36 0.341797 18.3208V7.32834C0.341797 3.28382 3.58607 0 7.58187 0H17.7592C21.7498 0 24.9993 3.28382 24.9993 7.32834V18.3208C24.994 22.36 21.7498 25.6438 17.7592 25.6438ZM7.58187 1.8803C4.6128 1.8803 2.19945 4.32308 2.19945 7.32834V18.3208C2.19945 21.3261 4.6128 23.7689 7.58187 23.7689H17.7592C20.7283 23.7689 23.1417 21.3261 23.1417 18.3208V7.32834C23.1417 4.32308 20.7283 1.8803 17.7592 1.8803H7.58187Z' />
    <path fill={Colors.peachLight} d='M12.6679 19.6012C8.9579 19.6012 5.93592 16.5423 5.93592 12.7871C5.93592 10.9336 6.68744 9.13364 7.99997 7.84261C9.28074 6.58372 10.9479 5.91945 12.6997 5.97302C14.7955 6.03731 16.5367 6.74978 17.7275 8.04081C18.8601 9.2622 19.437 10.9122 19.3946 12.8085C19.3576 14.6245 18.6484 16.328 17.3994 17.5977C16.145 18.8887 14.4621 19.6012 12.6679 19.6012ZM12.5144 8.26581C11.4242 8.26581 10.3869 8.69972 9.58241 9.4872C8.70916 10.3443 8.20638 11.5496 8.20638 12.7817C8.20638 15.2727 10.2069 17.2977 12.6679 17.2977C15.1183 17.2977 17.0765 15.3049 17.1294 12.7549C17.1559 11.4693 16.7907 10.3818 16.0762 9.60505C15.3035 8.76936 14.1127 8.30866 12.6362 8.26045C12.5938 8.26581 12.5568 8.26581 12.5144 8.26581Z' />
    <path fill={Colors.peachLight} d='M19.564 7.37119C20.4321 7.37119 21.1358 6.65887 21.1358 5.78017C21.1358 4.90148 20.4321 4.18915 19.564 4.18915C18.6959 4.18915 17.9921 4.90148 17.9921 5.78017C17.9921 6.65887 18.6959 7.37119 19.564 7.37119Z' />
  </svg>
