import React from 'react'

export default (props) =>
  <svg {...props} width='142' height='14' viewBox='0 0 142 14' fill='none'>
    <path d='M7.70977 8.30143H3.02328L2.04553 10.7835H0.52832L4.7765 0.821582H6.02398L10.2384 10.7835H8.72124L7.70977 8.30143ZM7.23775 7.16101L6.02398 4.20932C5.75426 3.57202 5.55196 2.96827 5.34967 2.4316H5.31595C5.11366 3.00181 4.91136 3.57202 4.64164 4.20932L3.46159 7.16101H7.23775Z' fill='white' />
    <path d='M12.5649 4.51118V5.58452C13.1044 4.71243 13.8798 4.24285 14.8239 4.24285C16.3411 4.24285 17.184 5.11494 17.184 6.72495V10.7835H15.9028V7.22808C15.9028 5.95348 15.4308 5.38327 14.4193 5.38327C13.3067 5.38327 12.5649 6.2889 12.5649 7.66412V10.7835H11.2837V4.51118H12.5649Z' fill='white' />
    <path d='M29.625 8.30143H24.9385L23.9607 10.7835H22.4435L26.6917 0.821582H27.9392L32.1536 10.7835H30.6364L29.625 8.30143ZM29.1529 7.16101L27.9392 4.20932C27.6694 3.57202 27.4671 2.96827 27.2649 2.4316H27.2311C27.0288 3.00181 26.8265 3.57202 26.5568 4.20932L25.3768 7.16101H29.1529Z' fill='white' />
    <path d='M33.1652 0.452637H34.4464V10.7836H33.1652V0.452637Z' fill='white' />
    <path d='M36.6375 0.452637H37.9187V10.7836H36.6375V0.452637Z' fill='white' />
    <path d='M43.3807 2.06263V0.821582H51.5736V2.06263H48.1684V10.7835H46.786V2.06263H43.3807Z' fill='white' />
    <path d='M57.2717 10.7836V9.71022C56.7322 10.5823 55.9231 11.0519 55.0464 11.0519C53.6304 11.0519 52.7875 10.1798 52.7875 8.50271V4.51122H54.0687V8.13375C54.0687 9.3748 54.5407 9.91147 55.4847 9.91147C56.4962 9.91147 57.2717 9.00584 57.2717 7.63062V4.51122H58.5529V10.7836H57.2717Z' fill='white' />
    <path d='M61.8909 4.51118V5.75223C62.4303 4.67889 63.1047 4.24285 63.9138 4.24285C64.5207 4.24285 65.0265 4.57827 65.2625 5.31619L64.1836 5.91994L64.0487 5.8864C63.8801 5.51744 63.7115 5.38327 63.3744 5.38327C62.6664 5.38327 61.9583 6.2889 61.9583 7.83183V10.7835H60.6771V4.51118H61.8909Z' fill='white' />
    <path d='M69.9493 10.8506C69.6121 10.9847 69.1738 11.0518 68.7355 11.0518C67.488 11.0518 66.78 10.3474 66.78 9.10637V5.58447H65.5999V4.51113H66.8137V2.59924L67.8926 1.96194H68.0949V4.51113H69.9493V5.58447H68.0949V8.77095C68.0949 9.64304 68.3309 9.91138 69.0389 9.91138C69.3087 9.91138 69.5447 9.84429 69.8144 9.71013L69.9493 9.77721V10.8506Z' fill='white' />
    <path d='M71.4999 0.452637H72.7811V10.7836H71.4999V0.452637Z' fill='white' />
    <path d='M75.8491 7.93248C75.8491 9.17353 76.692 9.94499 77.8046 9.94499C78.5801 9.94499 79.1195 9.60957 79.6253 8.83811L79.7264 8.80457L80.7042 9.34124C80.0973 10.4146 79.0521 11.0183 77.7709 11.0183C75.8154 11.0183 74.5005 9.60957 74.5005 7.6306C74.5005 5.58454 75.8154 4.20932 77.7035 4.20932C79.5579 4.20932 80.8053 5.45037 80.8053 7.59706C80.8053 7.69768 80.8053 7.73123 80.8053 7.83185H75.8491V7.93248ZM75.8828 6.95976H79.4904C79.423 5.91996 78.7487 5.28266 77.7372 5.28266C76.7594 5.31621 76.0514 5.91996 75.8828 6.95976Z' fill='white' />
    <path d='M81.8839 9.64308L82.7942 9.03932H82.8617C83.1988 9.64308 83.7383 10.012 84.4463 10.012C85.1544 10.012 85.5927 9.67662 85.5927 9.20703C85.5927 8.80453 85.3904 8.50265 84.1766 8.10015C82.5245 7.4964 82.1199 6.89264 82.1199 6.05409C82.1199 5.08138 82.9628 4.27637 84.4126 4.27637C85.5252 4.27637 86.4018 4.81304 86.739 5.61805L85.9298 6.15472H85.795C85.5589 5.61805 85.0869 5.31617 84.4126 5.31617C83.772 5.31617 83.4011 5.65159 83.4011 6.05409C83.4011 6.42305 83.5697 6.65785 84.8172 7.16098C86.4018 7.76473 86.8738 8.3014 86.8738 9.24058C86.8738 10.3139 85.8961 11.0854 84.4463 11.0854C83.3 11.0518 82.3897 10.5487 81.8839 9.64308Z' fill='white' />
    <path d='M94.0224 4.51118V5.58452C94.5618 4.71243 95.4047 4.24285 96.3825 4.24285C98.102 4.24285 99.282 5.55098 99.282 7.59704C99.282 9.60956 98.0346 11.0519 96.2813 11.0519C95.4047 11.0519 94.4944 10.5823 93.9887 9.77726V13.8358H92.7075V4.51118H94.0224ZM97.9671 7.59704C97.9671 6.18828 97.2591 5.38327 96.0453 5.38327C94.8316 5.38327 93.9887 6.32245 93.9887 7.63058C93.9887 8.97226 94.7978 9.87789 96.0116 9.87789C97.2254 9.91143 97.9671 9.0058 97.9671 7.59704Z' fill='white' />
    <path d='M102.147 4.51118V5.75223C102.687 4.67889 103.361 4.24285 104.17 4.24285C104.777 4.24285 105.283 4.57827 105.519 5.31619L104.44 5.91994L104.305 5.8864C104.136 5.51744 103.968 5.38327 103.631 5.38327C102.923 5.38327 102.215 6.2889 102.215 7.83183V10.7835H100.933V4.51118H102.147Z' fill='white' />
    <path d='M106.261 7.63058C106.261 5.71869 107.744 4.24285 109.733 4.24285C111.722 4.24285 113.206 5.71869 113.206 7.63058C113.206 9.54247 111.689 11.0519 109.733 11.0519C107.778 11.0519 106.261 9.54247 106.261 7.63058ZM111.891 7.63058C111.891 6.38953 110.981 5.38327 109.733 5.38327C108.486 5.38327 107.575 6.35599 107.575 7.63058C107.575 8.90517 108.519 9.87789 109.733 9.87789C110.981 9.91143 111.891 8.90517 111.891 7.63058Z' fill='white' />
    <path d='M114.42 7.63061C114.42 5.61809 115.735 4.24288 117.387 4.24288C118.331 4.24288 119.174 4.71246 119.713 5.58455C119.713 4.94726 119.713 4.24288 119.713 3.5385V0.452637H120.994V10.7836H119.747V9.71021C119.275 10.5488 118.331 11.0519 117.353 11.0519C115.6 11.0519 114.42 9.71021 114.42 7.63061ZM119.747 7.66415C119.747 6.35602 118.904 5.41684 117.724 5.41684C116.544 5.41684 115.768 6.28893 115.768 7.66415C115.768 9.07291 116.476 9.91146 117.656 9.91146C118.938 9.91146 119.747 9.00583 119.747 7.66415Z' fill='white' />
    <path d='M127.637 10.7836V9.71022C127.097 10.5823 126.288 11.0519 125.412 11.0519C123.995 11.0519 123.153 10.1798 123.153 8.50271V4.51122H124.434V8.13375C124.434 9.3748 124.906 9.91147 125.85 9.91147C126.861 9.91147 127.637 9.00584 127.637 7.63062V4.51122H128.918V10.7836H127.637Z' fill='white' />
    <path d='M130.603 7.63058C130.603 5.65161 131.918 4.24285 133.874 4.24285C135.121 4.24285 136.166 4.77952 136.841 5.95348L135.863 6.55724L135.762 6.5237C135.222 5.68515 134.649 5.38327 133.908 5.38327C132.761 5.38327 131.952 6.25536 131.952 7.63058C131.952 9.03934 132.795 9.91143 133.941 9.91143C134.75 9.91143 135.324 9.57601 135.829 8.73747L135.93 8.70392L136.875 9.30768C136.234 10.4481 135.121 11.0519 133.874 11.0519C131.885 11.0519 130.603 9.67664 130.603 7.63058Z' fill='white' />
    <path d='M142 10.8506C141.663 10.9847 141.224 11.0518 140.786 11.0518C139.539 11.0518 138.831 10.3474 138.831 9.10637V5.58447H137.651V4.51113H138.864V2.59924L139.943 1.96194H140.146V4.51113H142V5.58447H140.146V8.77095C140.146 9.64304 140.382 9.91138 141.09 9.91138C141.359 9.91138 141.595 9.84429 141.865 9.71013L142 9.77721V10.8506Z' fill='white' />
  </svg>
