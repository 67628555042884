import styled, { css } from 'styled-components'

import { leftWhispWhiteBg, singleWhispWhiteBg } from 'app/assets/images'
import { Breakpoints } from 'app/styles'

export const WhispComponent = styled.div`
  position: absolute;
  left: -340px;
  top: 240px;

  ${props => props.orientation === 'right' && css`
    right: -340px;
    left: initial;
  `}

  ${props => props.size === 'single' && css`
    top: 240px;
  `}

  ${props => props.type === 'mobile' && css`
    display: none;

    @media(max-width: ${Breakpoints.solutionComponent2}) {
      display: block;
      top: ${props.size === 'single' ? '200px' : '-26px'}
    }
  `}

  ${props => props.type === 'desktop' && css`
    @media(max-width: ${Breakpoints.solutionComponent2}) {
      display: none;
    }
  `}
`

export const Whisp = styled.div`
  background-image: url(${leftWhispWhiteBg});
  background-repeat: no-repeat;
  background-size: cover;
  width: 925px;
  height: 620px;

  @media(max-width: ${Breakpoints.solutionComponent3}) {
    display: none;
  }

  ${props => props.orientation === 'right' && css`
    transform: scaleX(-1);
  `}

  ${props => props.size === 'single' && css`
    background-image: url(${singleWhispWhiteBg});
    width: 640px;
    height: 390px;
  `}
`
