import styled, { css, keyframes } from 'styled-components'

import { Breakpoints } from 'app/styles'

const spinInLine2 = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(285deg);
  }
`

const spinOutLine2 = keyframes`
  0% {
    transform: rotate(198deg);
  }
  100% {
    transform: rotate(0);
  }
`

const spinInLine3 = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(-285deg);
  }
`

const spinOutLine3 = keyframes`
  0% {
    transform: rotate(-198deg);
  }
  100% {
    transform: rotate(0);
  }
`

const spinInLine1 = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: rotate(360deg);
  }
`

const spinOutLine1 = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(360deg);
  }
`

const animationDuration = '350ms'
const animationEasing = 'ease'

export const SourceAsteriskComponent = styled.div`
  display: inline;

  @media(max-width: ${Breakpoints.main}){
    svg {
      width: 10px;
      height: 10px;
    }
  }
`

export const Line1 = styled.path`
  transform-origin: center;
  animation: ${spinInLine1} ${animationDuration} ${animationEasing} forwards;

  ${props => props.open && css`
    animation: ${spinOutLine1} ${animationDuration} ${animationEasing} forwards;
    animation-direction: normal;
  `}
`
export const Line2 = styled.path`
  transform-origin: center;
  animation: ${spinOutLine2} ${animationDuration} ${animationEasing} forwards;

  ${props => props.open && css`
    animation: ${spinInLine2} ${animationDuration} ${animationEasing} forwards;
    animation-direction: normal;
  `}
`
export const Line3 = styled.path`
  transform-origin: center;
  animation: ${spinOutLine3} ${animationDuration} ${animationEasing} forwards;

  ${props => props.open && css`
    animation: ${spinInLine3} ${animationDuration} ${animationEasing} forwards;
    animation-direction: normal;
  `}
`
