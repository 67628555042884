import styled, { createGlobalStyle, css } from 'styled-components'
import { Colors, Breakpoints } from 'app/styles'

import { LeftArrow } from 'app/ui-kit/Icons'

export const FlickityStyles = createGlobalStyle`
  .flickity-viewport {
    overflow: hidden;
  }

  .flickity-enabled {
    position: relative;
    &:focus {
      outline: 0;
    }
  }

  .flickity-page-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style-type: none;
    margin: 17px 0 0 0;
    padding: 0;
  }

  .flickity-page-dots .dot {
    width: 8px;
    height: 8px;
    border-radius: 6px;
    margin-right: 24px;
    transition: all .2s ease;
    background-color: ${Colors.periwinkle20alpha};
    cursor: pointer;
    transition: all .2s ease-in-out;

    &:last-child {
      margin: 0;
    }

    &:hover {
    }

    &.is-selected {
      background-color: ${Colors.periwinkle};
      transform: scale(1.33);
    }
  }

  .flickity-fade-graph .flickity-slider {
    transform: none !important;

      > div {
        left: 0 !important;
        opacity: 0;
        transition: opacity 0.3s ease-out;
        z-index: -1;

        &.is-selected {
          opacity: 1;
          z-index: 0;
        }
      }
  }

  .flickity-fade .flickity-slider {
    @media(min-width: ${Breakpoints.main}){
      transform: none !important;

      > div {
        left: 0 !important;
        opacity: .55;
        transition: opacity 0.7s ease-in-out;
        z-index: -1;

        &.is-selected {
          opacity: 1;
          z-index: 0;
        }
      }
    }
  }
`

export const CarouselComponent = styled.div`
  position: relative;
`

const pagination = css`
  background-color: ${Colors.peach};
  width: 27px;
  height: 27px;
  border-radius: 50%;
  border: 0;
  position: absolute;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;

  &:hover {
    background-color: ${Colors.peachMedium};
  }

  path {
    stroke: ${Colors.white};
  }

  ${props => props.paginationType === 'phone' && css`
    transform: scale(1.4);
  `}

  ${props => !props.enabled && css`
    opacity: 0.5;
    cursor: initial;

    &:hover {
      background-color: ${Colors.peach};
    }
  `}
`

export const Previous = styled.div`
  ${pagination};
  left: -13px;
  top: 50%;
  margin-top: -13px;

  ${props => props.paginationType === 'graph' && css`
    left: -35px;
  `}
`

export const Next = styled.div`
  ${pagination};
  right: -13px;
  top: 50%;
  margin-top: -13px;
  transform: rotate(180deg);

  ${props => props.paginationType === 'phone' && css`
    transform: scale(1.4) rotate(180deg);
  `}

  ${props => props.paginationType === 'graph' && css`
    right: -35px;
  `}
`

export const StyledLeftArrow = styled(LeftArrow)``

export const Pagination = styled.div`
  @media(max-width: ${Breakpoints.solutionComponent3}){
    display: none;
  }

  ${props => props.paginationType === 'phone' && css`
    display: none;

    @media(max-width: ${Breakpoints.largest}){
      display: block;
    }

    @media(max-width: ${Breakpoints.main}){
      display: none;
    }
  `}
`
