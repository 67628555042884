import React, { Component } from 'react'

import * as S from './styles'

import HowItWorksCard from './HowItWorksCard/HowItWorksCard'
import howItWorksData from './howItWordsData'

export default class PauseReflect extends Component {
  renderCards = () => {
    return <S.Cards>
      { Object.values(howItWorksData).map((item, index) => <HowItWorksCard key={index} data={item} />) }
    </S.Cards>
  }
  render () {
    return <S.PauseReflectComponent>
      <S.SkyBlueWave />
      <S.MaxWidth>
        <S.Title>Pause. Reflect. Consider.</S.Title>
        <S.Description>Sift offers a break from the never-ending news cycle—a space where you can pause and think about the issues behind the headlines. Here’s how it works.</S.Description>
        { this.renderCards() }
      </S.MaxWidth>
    </S.PauseReflectComponent>
  }
}
