export default {
  newsAnxiety: {
    title: 'News anxiety',
    links: [{
      title: 'Understanding fear-based media',
      source: 'Psychology Today',
      url: 'https://www.psychologytoday.com/us/blog/two-takes-depression/201106/if-it-bleeds-it-leads-understanding-fear-based-media'
    }, {
      title: 'How your fear and outrage are being sold for profit',
      source: 'Medium',
      url: 'https://medium.com/@tobiasrose/the-enemy-in-our-feeds-e86511488de'
    }, {
      title: 'Stress in America',
      source: 'APA: Report',
      url: 'https://www.apa.org/news/press/releases/stress/2017/state-nation.pdf'
    }, {
      title: 'How people feel about the news',
      source: 'Pew Research',
      url: 'http://www.journalism.org/2010/03/01/how-people-use-news-and-feel-about-news/'
    }, {
      title: 'How Twitter fuels anxiety',
      source: 'The Atlantic',
      url: 'https://www.theatlantic.com/technology/archive/2017/07/how-twitter-fuels-anxiety/534021/'
    }, {
      title: 'Do we really need to follow the news?',
      source: 'Freakanomics Podcast',
      url: 'http://freakonomics.com/podcast/why-do-we-really-follow-the-news-a-new-freakonomics-radio-episode/'
    }, {
      title: 'Compassion fatigue: How the news impacts mental health',
      source: 'Utah Public Radio',
      url: 'https://www.upr.org/post/compassion-fatigue-how-news-can-impact-mental-health'
    }, {
      title: 'Trauma-aware journalism',
      source: 'TED Talk: Elana Newman',
      url: 'https://www.youtube.com/watch?v=HXPfJ8rQZ_8'
    }, {
      source: 'Dart Center for Journalism and Trauma',
      url: 'https://dartcenter.org/'
    }, {
      source: 'Media Psychology Review',
      url: 'http://mprcenter.org/review/'
    }, {
      source: 'Solutions Journalism Network',
      url: 'https://www.solutionsjournalism.org/'
    }, {
      source: 'Compassion Fatigue Awareness Project',
      url: 'http://www.compassionfatigue.org/'
    }, {
      source: 'The Yale Cultural Cognition Project',
      url: 'http://www.culturalcognition.net/'
    }, {
      source: 'Editorial guide for covering violence',
      url: 'https://learninglab.solutionsjournalism.org/en/courses/violence-guide/introduction/welcome'
    }]
  },
  mindful: {
    title: 'Mindful media and stress reduction',
    links: [{
      title: 'How to establish a media diet',
      source: 'Columbia Journalism Review',
      url: 'https://archives.cjr.org/news_literacy/slow_news_news_diet.php'
    }, {
      title: 'The Information Diet',
      source: 'Clay Johnson',
      url: 'http://informationdiet.com/'
    }, {
      title: 'Mindfulness-based stress reduction',
      source: 'UMass Medical School',
      url: 'https://www.umassmed.edu/cfm/mindfulness-based-programs/mbsr-courses/about-mbsr/mbsr-standards-of-practice/'
    }, {
      title: 'Slow Media',
      source: 'Jennifer Rauch',
      url: 'https://global.oup.com/academic/product/slow-media-9780190641795?cc=it&lang=en&'
    }, {
      title: 'Mindfulness for reducing anxiety',
      source: 'Anxiety.org',
      url: 'https://www.anxiety.org/can-mindfulness-help-reduce-anxiety'
    }, {
      title: 'Full Catastrophe Living: Using Mindfulness to Reduce Stress',
      source: 'Jon Kabat-Zinn',
      url: 'https://www.amazon.com/Full-Catastrophe-Living-Kabat-Zinn-Borysenko/dp/B00849E5RI/ref=pd_lpo_sbs_14_t_1?_encoding=UTF8&psc=1&refRID=7AQW4P6Y6RCRQT4HVQZN'
    }, {
      title: 'Overload!',
      source: 'Columbia Journalism Review',
      url: 'https://archives.cjr.org/feature/overload_1.php'
    }]
  }
}
