import styled, { css } from 'styled-components'

import { Breakpoints } from 'app/styles'
import { AppleLogo } from 'app/ui-kit/Icons'

export const AppStoreButtonComponent = styled.div``

export const StyledA = styled.a`
  &:hover {
    text-decoration: none;
  }
`

export const Apple = styled(AppleLogo)`
  margin-right: 15px;
  margin-top: -3px;
  margin-left: -4px;

  @media(max-width: ${Breakpoints.main}){
    width: 16px;
    height: 20px;
    margin-right: 10px;
  }

  ${props => props.size === 'small' && css`
    width: 16px;
    height: 20px;
  `}
`

export const ButtonText = styled.div`

`
