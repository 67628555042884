import styled from 'styled-components'

import { Breakpoints, Colors } from 'app/styles'
import { skyBlueWave } from 'app/assets/images'
import { AppStoreButton } from 'app/components'

export const TopicsComponent = styled.div`
  background-color: ${Colors.skyBlue};
  padding-bottom: 225px;
  position: relative;
  margin-top: 373px;
  display: flex;
  justify-content: center;

  @media(max-width: ${Breakpoints.largest}){
    padding-bottom: 80px;
  }

  @media(max-width: ${Breakpoints.main}){
    padding-bottom: 180px;
  }

  @media(max-width: ${Breakpoints.smallest}){
    margin-top: 313px;
    position: relative;
    z-index: 10;
    padding-bottom: 180px;
  }
`

export const SkyBlueWave = styled.div`
  position: absolute;
  top: -372px;
  background-image: url(${skyBlueWave});
  background-repeat: no-repeat;
  background-size: 1800px auto;
  background-position: top;
  width: 1800px;
  height: 373px;
  background-size: cover;
`

export const TopicsContent = styled.div`
  margin-top: -82px;
  display: flex;
  align-items: center;
  z-index: 2;
  position: relative;

  @media(max-width: ${Breakpoints.largest}){
    align-items: flex-start;
    margin-top: -280px;
  }

  @media(max-width: ${Breakpoints.main}){
    flex-direction: column-reverse;
    align-items: center;
  }
`

export const StyledH5 = styled.h5`
  color: ${Colors.blue};
  margin-bottom: 32px;
  margin: -30px 0 21px 0;

  @media(max-width: ${Breakpoints.largest}){
    margin: 0 0 24px 0;
  }
`

export const StyledH2 = styled.h2`
  margin: 0 0 30px 0;
  max-width: 900px;

  @media(max-width: ${Breakpoints.largest}){
    max-width: 470px;
  }
`

export const Description = styled.div`
  margin-bottom: 66px;

  @media(max-width: ${Breakpoints.largest}){
    max-width: 470px;
  }

  @media(max-width: ${Breakpoints.main}){
    margin-bottom: 40px;
  }
`

export const StyledA = styled.a`
  &:hover {
    text-decoration: none;
  }
`

export const Content = styled.div`
  margin-left: 30px;
  width: 684px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  @media(max-width: ${Breakpoints.largest}){
    margin-left: -30px;
    width: auto;
    margin-right: 56px;
    margin-top: 100px;
  }

  @media(max-width: ${Breakpoints.main}){
    margin: 0 25px;
    text-align: center;
    align-items: center;
  }
`

export const DesktopAppStoreButton = styled(AppStoreButton)`
  @media(max-width: ${Breakpoints.main}) {
    display: none;
  }
`
export const MobileAppStoreButton = styled(AppStoreButton)`
  display: none;

  @media(max-width: ${Breakpoints.main}) {
    display: block;
    margin-top: 60px;
  }
`
