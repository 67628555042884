import styled from 'styled-components'

import { Breakpoints, Templates, Type } from 'app/styles'

export const HowItWorksCardComponent = styled.div`
  ${Templates.card};
  margin: 0 60px 60px 0;

  &:nth-child(even) {
    margin: 0 0 60px 0;

    @media(max-width: ${Breakpoints.smallest}){
      margin: 0 25px 25px 25px;;
    }
  }

  @media(max-width: ${Breakpoints.largest}) {
    margin: 0 0 60px 0;
  }

  @media(max-width: ${Breakpoints.smallest}){
    margin: 0 25px 25px 25px;;
  }
`

export const IconAndTitle = styled.div`
  display: flex;
  margin-bottom: 10px;
  align-items: center;

  svg {
    @media(max-width: ${Breakpoints.smallest}){
      width: 69px;
      height: 69px;
    }
  }
`
export const Title = styled.div`
  ${Type.cardTitle};
  margin-left: 30px;

  @media(max-width: ${Breakpoints.smallest}){
    margin-left: 10px;
  }
`
export const Description = styled.div`
  ${Type.cardDescription};
`
