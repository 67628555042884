import styled, { css } from 'styled-components'

import { Colors, Type, Templates, Breakpoints } from 'app/styles'
import { DateMarker } from 'app/ui-kit/Icons'

export const GraphCarouselComponent = styled.div`
  ${Templates.solutionBox};
  display: flex;
  flex-direction: column;

  .flickity-viewport {
    border-left: 2px solid ${Colors.graphRules};
    border-right: 2px solid ${Colors.graphRules};
  }
`

export const Title = styled.div`
  ${Type.graphTitle};
  color: ${Colors.periwinkle};
  margin-bottom: 8px;
`

export const Description = styled.div`
  color: ${Colors.periwinkle};
  font-size: 18px;
  line-height: 24px;

  @media(max-width: ${Breakpoints.solutionComponent3}){
    font-size: 14px;
    line-height: 20px;
  }
`

export const ImageWrap = styled.div`
  background-color: ${Colors.white};
  height: 145px;

  @media(max-width: ${Breakpoints.solutionComponent3}){
    height: 103px;
    width: 277px;
  }
`

export const StyledImage = styled.img`
  width: 383px;
  height: 145px;

  @media(max-width: ${Breakpoints.solutionComponent3}){
    height: 103px;
    width: 277px;
  }
`

export const Texts = styled.div`
  position: relative;
  height: 220px;

  @media(max-width: ${Breakpoints.solutionComponent3}){
    text-align: center;
  }

  @media(max-width: ${Breakpoints.main}){
    height: 200px;
  }

  @media(max-width: ${Breakpoints.solutionComponent3}){
    height: 150px;
  }
`

export const TextWrap = styled.div`
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 0;
  top: 0;
  transform: opacity .3s ease-out;

  ${props => props.selected && css`
    opacity: 1;
    visibility: visible;
  `}
`

export const Year = styled.div`
  position: absolute;
  left: 50%;
  margin-left: -35px;
  top: -30px;
  z-index: 2;
  color: ${Colors.white};
  font-weight: bold;
  font-size: 18px;
  width: 70px;
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
`

export const CarouselWrap = styled.div`
  width: 383px;
  align-self: center;
  position: relative;
  margin-top: 30px;

  @media(max-width: ${Breakpoints.solutionComponent3}){
    width: 272px;
  }
`

export const DateMarkerWrap = styled.div`
position: absolute;
left: 50%;
margin-left: -35px;
top: -30px;
z-index: 2;
pointer-events: none;

@media(max-width: ${Breakpoints.solutionComponent3}){
  height: 130px;
  overflow: hidden;
}
`

export const StyledDateMarker = styled(DateMarker)`

`
