import styled from 'styled-components'

import { BreakerWave } from 'app/ui-kit/Icons'
import { Breakpoints, Colors, Type, Templates } from 'app/styles'
import { whySiftBg, fade } from 'app/assets/images'

export const WhySiftPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: -140px;
  background-color: ${Colors.white};
`

export const WhySiftBg = styled.div`
  background-image: url(${whySiftBg});
  width: 1800px;
  height: 931px;
  background-size: 1440px 931px;
  background-repeat: no-repeat;
  position: absolute;
  background-position: top right;
  top: 0;
  left: 0;
  z-index: 1;
  margin-left: -900px;
  left: 50%;

  @media(max-width: ${Breakpoints.main}){
    background-position: 100px 40px;
  }

`

export const StyledBreakerWave = styled(BreakerWave)`
  margin-top: -146px;
  margin-bottom: -180px;
  z-index: 2;

  @media(max-width: ${Breakpoints.smallest}){
    width: 1322px;
    height: 285px;
  }
`

export const Hero = styled.div`
  height: 550px;
  background-size: cover;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 2;
  background-color: ${Colors.darkBlue};
  position: relative;
`

export const MaxWidth = styled.div`
  ${Templates.contentPageMaxWidth};
  margin-bottom: 160px;

  @media(max-width: ${Breakpoints.main}){
    margin-bottom: 0;
  }
`

export const Span = styled.span``

export const Title = styled.h2`
  ${Templates.contentPageTitle};
`

export const SectionTitle = styled.div`
  padding-bottom: 28px;

  &:after {
    content: '';
    display: block;
    width: 64px;
    height: 4px;
    background-color: ${Colors.blue};
    margin-top: 20px;
  }
`

export const StyledH4 = styled.h4`
  display: inline;
`

export const Italic = styled.h4`
  display: inline;
  font-style: italic;
`

export const P = styled.div`
  ${Type.body};
  margin-top: 0;
  margin-bottom: 38px;
`

export const Section = styled.div`
  margin-bottom: 80px;

  &:last-child {
    margin-bottom: 0;
  }
`

export const ContentWrap = styled.div`
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: ${Colors.white};
`

export const Fade = styled.div`
  position: absolute;
  bottom: -200px;
  left: 0;
  background-image: url(${fade});
  background-position: top left;
  background-repeat: repeat-x;
  background-size: 600px 200px;
  height: 200px;
  width: 100%;
`
