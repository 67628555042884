import styled, { css } from 'styled-components'

import { Breakpoints, Colors, Type, Templates } from 'app/styles'
import { Minus, Plus } from 'app/ui-kit/Icons'
import { linePattern } from 'app/assets/images'

export const QuizComponent = styled.div`
  ${Templates.solutionBox};
  display: flex;
  flex-direction: column;
`
export const Title = styled.div`
  ${Type.questionBody};
  color: ${Colors.periwinkle};
  margin-bottom: 34px;
`

export const Quiz = styled.div`
  display: flex;
  flex-direction: column;
`

export const Labels = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  margin-bottom: 18px;
  flex-shrink: 0;
`

export const Label = styled.div`
  text-transform: uppercase;
  color: ${Colors.periwinkle};
  font-size: 12px;
  font-weight: bold;
  max-width: 126px;
  line-height: normal;

  &:last-child {
    text-align: right;
  }
`

export const Questions = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const Question = styled.div`
  display: flex;
  margin-bottom: 17px;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  position: relative;
  flex-shrink: 0;
`

const controls = css`
  cursor: pointer;
  z-index: 2;
  position: relative;
  transition: all .2s ease;

  &:hover {
    opacity: 0.5;
  }
`
export const StyledMinus = styled(Minus)`
  ${controls};
`
export const StyledPlus = styled(Plus)`
  ${controls};
`

const dottedLineWidth = 463 - 48

export const DottedLine = styled.div`
  background-image: linear-gradient(to right, ${Colors.gray} 24%, rgba(255,255,255,0) 0%);
  background-position: bottom;
  background-size: 8px 1px;
  background-repeat: repeat-x;
  width: ${dottedLineWidth}px;
  height: 2px;
  position: absolute;
  top: 17px;
  left: 0;
  z-index: 1;
  box-sizing: border-box;
  margin: 0 24px;

  @media(max-width: ${Breakpoints.smallest}) {
    width: calc(100% - 48px);
  }
`

export const ChoiceText = styled.div`
  transition: transform .3s ease;

  ${props => (props.state === 'correct' || props.state === 'incorrect') && css`
    transform: translate3d(-13px, 0, 0);
  `}
`

export const QuestionTitle = styled.div`
  width: 200px;
  height: 36px;
  background-color: ${Colors.peach};
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.white};
  transition: transform 500ms cubic-bezier(0.680, -0.550, 0.265, 1.550), background-color .3s ease;
  ${Type.smallBody};
  z-index: 3;
  position: relative;
  font-family: 'RingsideCondensed';
  font-weight: 500;
  user-select: none;

  cursor: pointer;

  &:hover {
    background-color: ${Colors.peachMedium};
  }

  ${props => props.incorrect && css`
    background-color: ${Colors.peachLight};
    background-image: url(${linePattern});
    background-size: 5px 5px;
    background-repeat: repeat;
  `}

  ${props => props.position === 1 && css`
    transform: translate3d(136px, 0, 0);
  `}

  ${props => props.position === -1 && css`
    transform: translate3d(-136px, 0, 0);
  `}

  @media(max-width: ${Breakpoints.solutionComponent3}){
    width: 140px;

    ${props => props.position === 1 && css`
      transform: translate3d(96px, 0, 0);
    `}

    ${props => props.position === -1 && css`
      transform: translate3d(-96px, 0, 0);
    `}
  }
`
