import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import * as S from './styles'
import Analytics from 'app/analytics'

import Blank from './Blank/Blank'
import { CorrectnessIndicator } from 'app/components'

const choices = {
  mentalIllness: {
    slug: 'mentalIllness',
    display: 'mental illness',
    correct: true
  },
  insanity: {
    slug: 'insanity',
    display: 'insanity'
  },
  madness: {
    slug: 'madness',
    display: 'madness'
  }
}

@withRouter
export default class FillIn extends Component {
  state = { answer: null }

  selectAnswer = (answer) => {
    this.setState({ answer })

    Analytics.event({
      eventType: 'WIDGET INTERACTION',
      pathname: this.props.location.pathname,
      props: {
        widgetName: 'Fill in',
        slug: 'answer',
        value: answer
      }
    })
  }

  getStateForChoice = (slug) => {
    const { answer } = this.state
    if (answer == null) return null
    if (choices[slug].correct) return 'correct'
    return 'incorrect'
  }

  render () {
    const { answer } = this.state
    return <S.FillInComponent>
      <S.Sentance>
        <span>The link between </span><Blank answer={'mental illness'} filledIn={!!answer} /> <span>and gun violence often gets exaggerated and oversimplified.</span>
      </S.Sentance>
      <S.Choices>
        { Object.values(choices).map(item => {
          const state = this.getStateForChoice(item.slug)
          return <S.Choice
            onClick={() => this.selectAnswer(item.slug)}
            key={item.slug}
            state={state}
          >
            <S.ChoiceText state={state}>{item.display}</S.ChoiceText>
            <CorrectnessIndicator state={state} />
          </S.Choice>
        })}
      </S.Choices>
    </S.FillInComponent>
  }
}
