import React, { Component } from 'react'
import { withRouter, matchPath } from 'react-router-dom'

import * as S from './styles'

import { navItems } from 'app/data'
import { AppStoreButton } from 'app/components'
import routes from '../../routes/routes'
import Analytics from 'app/analytics'

@withRouter
export default class BasicNav extends Component {
  state = {
    mobileNavOpen: false,
    scrolledNavOpen: false,
    scrollY: 0,
    isScrolled: false
  }

  getThemesForRoute = () => {
    const { location } = this.props
    const activeRoute = routes[0].routes.find(route => matchPath(location.pathname, route))
    return activeRoute.navTheme
  }

  updateScrolledNavOpen = () => {
    const scrollY = window.scrollY
    const { scrolledNavOpen } = this.state

    if (scrollY > 1000 && !scrolledNavOpen) {
      this.setState({ scrolledNavOpen: true })
    } else if (scrollY <= 1000 && scrolledNavOpen) {
      this.setState({ scrolledNavOpen: false })
    }

    if (scrollY > 10) {
      this.setState({ isScrolled: true })
    } else {
      this.setState({ isScrolled: false })
    }
  }

  componentDidMount = () => {
    if (!__BROWSER__) return

    this.updateScrolledNavOpen()
    window.addEventListener('scroll', this.updateScrolledNavOpen)
  }

  toggleMobileNav = () => {
    this.setState({ mobileNavOpen: !this.state.mobileNavOpen }, () => {
      Analytics.event({
        eventType: 'NAV ITEM CLICK',
        pathname: this.props.location.pathname,
        props: {
          type: 'toggle mobile nav',
          value: this.state.mobileNavOpen ? 'open' : 'closed'
        }
      })
    })
  }

  mobileNavItemClick = (path) => {
    this.setState({ mobileNavOpen: false })

    Analytics.event({
      eventType: 'NAV ITEM CLICK',
      pathname: this.props.location.pathname,
      props: {
        type: 'mobile',
        path
      }
    })
  }

  desktopNavItemClick = (path) => {
    Analytics.event({
      eventType: 'NAV ITEM CLICK',
      pathname: this.props.location.pathname,
      props: {
        type: 'desktop',
        path
      }
    })
  }

  renderMobileNav = () => {
    const items = Object.values(navItems)

    return <S.MobileNavExpanded isOpen={this.state.mobileNavOpen.toString()}>
      <S.MobileNavItems>
        { items.map((item, index) => {
          return <S.MobileNavItem
            onClick={() => { this.mobileNavItemClick(item.url) }}
            to={item.url}
            key={index}
          >
            <S.MobileNavItemText>{item.display}</S.MobileNavItemText>
          </S.MobileNavItem>
        })
        }
      </S.MobileNavItems>
    </S.MobileNavExpanded>
  }

  renderDefaultNav = () => {
    const items = Object.values(navItems)
    const { mobileNavOpen, isScrolled } = this.state
    const theme = this.getThemesForRoute()

    return <S.NavWrapper isScrolled={isScrolled} theme={theme} mobileNavOpen={mobileNavOpen}>
      <S.MaxWidth>
        <S.LogoWrap to='/'>
          <S.StyledLogo theme={theme} mobileNavOpen={mobileNavOpen} />
        </S.LogoWrap>
        <S.NavItems>
          { items.map((item, index) =>
            <S.NavItem
              to={item.url}
              key={index}
              onClick={() => { this.desktopNavItemClick(item.url) }}
            >
              {item.display}
            </S.NavItem>)
          }
          <AppStoreButton size='small' />
        </S.NavItems>
        <S.MobileNav>
          <S.MobileNavController onClick={this.toggleMobileNav}>
            <S.MenuIconWrap isOpen={mobileNavOpen}>
              <S.StyledX theme={theme} />
            </S.MenuIconWrap>
            <S.MenuIconWrap isOpen={!mobileNavOpen}>
              <S.StyledMenu theme={theme} />
            </S.MenuIconWrap>
          </S.MobileNavController>
        </S.MobileNav>
      </S.MaxWidth>
    </S.NavWrapper>
  }

  render () {
    const { scrolledNavOpen } = this.state
    const theme = this.getThemesForRoute()

    if (theme === 'hidden') return null

    return <div>
      <this.props.persistentNav theme={theme} />
      <S.BasicNavComponent scrolledNavOpen={scrolledNavOpen}>
        { this.renderMobileNav() }
        { this.renderDefaultNav() }
      </S.BasicNavComponent>
    </div>
  }
}
