import styled, { css } from 'styled-components'

import { Breakpoints, Type, Colors } from 'app/styles'
import { LinkArrow } from 'app/ui-kit/Icons'

export const animationDuration = 300
export const delayDuration = 130
export const sourcePadding = 76
export const TextWithSourceComponent = styled.span`
  display: inline;
`

export const SourceToggle = styled.div`
  cursor: pointer;
  display: inline;
  color: ${Colors.brand};
  margin-right: .25em;
  vertical-align: super;
  line-height: 10px;
`

export const X = styled.div`
  color: ${Colors.brand};
  vertical-align: super;
  font-size: 22px;
  display: inline;
  margin-left: 1px;
`

export const SourceWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  max-width: 650px;
`

export const Source = styled.div`
  opacity: 0;
  transition: padding ${animationDuration}ms ease, height ${animationDuration}ms ease, transform ${animationDuration}ms ease ${delayDuration}ms, opacity ${animationDuration * 0.4}ms ease;
  transform: translate3d(0, -50px, 0);
  margin-left: 54px;
  padding-top: 0;
  position: absolute;
  pointer-events: none;
  visibility: hidden;

  ${props => props.open && css`
    opacity: 1;
    pointer-events: all;
    visibility: visible;
    transform: translate3d(0, 0, 0);
    padding-top: 30px;
    transition: height ${animationDuration}ms ease, transform ${animationDuration}ms ease, opacity ${animationDuration}ms ease ${delayDuration}ms;

    @media(max-width: ${Breakpoints.main}){
      padding-top: 20px;
    }
  `}
`

export const Author = styled.div`
  font-size: 16px;
  line-height: initial;
  font-weight: bold;

  @media(max-width: ${Breakpoints.main}){
    font-size: 14px;
    margin-bottom: 4px;
  }
`

export const TitleAndArrow = styled.div`
  margin-bottom: 12px;
  cursor: pointer;
  display: block;
`

export const Arrow = styled(LinkArrow)`
  display: inline;
  margin-left: 6px;
`

export const Title = styled.a`
  font-family: ${Type.secondaryFontFace};
  color: ${Colors.brand};
  font-size: 19px;
  line-height: initial;
  font-weight: 700;

  &:hover {
    color: ${Colors.brand};
    opacity: 0.5;
  }

  @media(max-width: ${Breakpoints.main}){
    font-size: 16px;
    line-height: 22px;
  }
`

export const Quote = styled.div`
  font-family: ${Type.secondaryFontFace};
  font-size: 19px;
  line-height: 26px;
  padding-left: 12px;
  border-left: 1px solid ${Colors.brand};

  @media(max-width: ${Breakpoints.main}){
    font-size: 16px;
    line-height: 22px;
  }
`

export const Span = styled.span`
  ${Type.body};
  background-image: linear-gradient(${Colors.brand20alpha}, ${Colors.brand20alpha});
  background-position: 0 24px;
  background-repeat: repeat-x;
  background-size: 5px 10px;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  ${props => props.open && css`
    background-image: linear-gradient(${Colors.brand40alpha}, ${Colors.brand40alpha});
  `}

  @media(max-width: ${Breakpoints.main}){
    background-position: 0 16px;
  }
`

export const LastWordAndArrow = styled.div`
  display: inline;
  white-space: nowrap;
`

export const TextWrap = styled.span`
  &:hover ${Span} {
    background-image: linear-gradient(${Colors.brand40alpha}, ${Colors.brand40alpha});
  }
`
