import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import * as S from './styles'
import Analytics from 'app/analytics'

const ratings = ['1', '2', '3', '4', '5']

@withRouter
export default class Reflection extends Component {
  state = { selectedRating: null }
  selectRating = (selectedRating) => {
    this.setState({ selectedRating })

    Analytics.event({
      eventType: 'WIDGET INTERACTION',
      pathname: this.props.location.pathname,
      props: {
        widgetName: 'Reflection',
        slug: 'rating',
        value: selectedRating
      }
    })
  }
  render () {
    const { selectedRating } = this.state
    return <S.ReflectionComponent>
      <S.Title>After reading this Sift topic, I feel anxious.</S.Title>
      <S.RatingPicker>
        <S.Labels>
          <S.Label>Disagree</S.Label>
          <S.Label>Agree</S.Label>
        </S.Labels>
        <S.Ratings>
          { ratings.map(item => {
            const selected = selectedRating === item
            const unselected = selectedRating && selectedRating !== item
            return <S.Rating key={item} unselected={unselected} selected={selected} onClick={() => this.selectRating(item)}>{item}</S.Rating>
          })
          }
        </S.Ratings>
      </S.RatingPicker>
    </S.ReflectionComponent>
  }
}
