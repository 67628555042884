import styled, { css } from 'styled-components'

import { Type, Colors, Templates } from 'app/styles'

import { linePattern } from 'app/assets/images'

export const FillInComponent = styled.div`
  ${Templates.solutionBox};
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const Sentance = styled.div`
  ${Type.questionBody};
  color: ${Colors.periwinkle};
`
export const Choices = styled.div`
  display: flex;
  margin-top: 22px;
  flex-wrap: wrap;
`

export const ChoiceText = styled.div`
  transition: transform .3s ease;

  ${props => (props.state === 'correct' || props.state === 'incorrect') && css`
    transform: translate3d(-13px, 0, 0);
  `}
`
export const Choice = styled.div`
  ${Type.smallBody};
  font-weight: bold;
  height: 36px;
  background-color: ${Colors.peach};
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.white};
  font-family: 'RingsideCondensed';
  font-weight: 500;
  padding: 0 33px;
  margin-right: 10px;
  cursor: pointer;
  flex-shrink: 0;
  margin-bottom: 14px;
  transition: all .3s ease-in-out;
  position: relative;

  &:hover {
    background-color: ${Colors.peachMedium};
  }

  ${props => props.state === 'correct' && css`

    &:hover {
      cursor: initial;
      background-color: ${Colors.peach};
    }
  `}

  ${props => props.state === 'incorrect' && css`
    opacity: .5;
    background-image: url(${linePattern});
    background-size: 5px 5px;
    background-repeat: repeat;

    &:hover {
      cursor: initial;
      background-color: ${Colors.peach};
    }
  `}
`
