import React, { Component } from 'react'

import * as S from './styles'

export default class CorrectnessIndicator extends Component {
  render () {
    const { state } = this.props
    return <S.CorrectnessIndicatorComponent>
      <S.StyledCheck state={state} />
      <S.StyledX state={state} />
    </S.CorrectnessIndicatorComponent>
  }
}
