import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import * as S from './styles'
import { FloatingNews, AppStoreButton, FloatingNewsMobile } from 'app/components'
import Contact from './Contact'
import Solution from './Solution'
import TheProblem from './TheProblem'
import Topics from './Topics'

const mapStateToProps = (state) => {
  const { floatingPersonNum } = state.app
  return { floatingPersonNum }
}

@connect(mapStateToProps)
export default class Home extends PureComponent {
  render () {
    return <S.HomeComponent>
      <S.RightWhisp size='double' orientation='right' />
      <S.LeftWhisp size='double' />
      <S.MobileHeroNoise />
      <S.Hero>
        <FloatingNewsMobile floatingPersonNum={this.props.floatingPersonNum} />
        <S.Copy>
          <S.HeroTitle>Feel news differently</S.HeroTitle>
          <S.HeroDescription>Sift unpacks contentious issues to help reduce news anxiety.</S.HeroDescription>
          <AppStoreButton />
        </S.Copy>
        <FloatingNews floatingPersonNum={this.props.floatingPersonNum} />
      </S.Hero>
      <TheProblem />
      <Solution />
      <Topics />
      <Contact />
    </S.HomeComponent>
  }
}
