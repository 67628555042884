import styled, { css } from 'styled-components'

import { Colors, Breakpoints, Type, Templates } from 'app/styles'
import { BreakerWave } from 'app/ui-kit/Icons'
import { fade, periwinkleWave } from 'app/assets/images'

export const StyledBreakerWave = styled(BreakerWave)`
  margin-top: -146px;
  margin-bottom: -180px;
  position: relative;

  @media(max-width: ${Breakpoints.smallest}){
    width: 1322px;
    height: 285px;
  }
`
export const ResourcesPage = styled.div`
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${Colors.white};
  background-image: url(${fade});
  background-position: top left;
  background-repeat: repeat-x;
  background-size: 600px 200px;
`

export const Hero = styled.div`
  background-color: ${Colors.periwinkle};
  background-image: url(${periwinkleWave});
  background-position: center top;
  height: 550px;
  background-size: cover;
  width: 100%;
  margin-top: -140px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
`
export const Title = styled.h2`
  ${Templates.contentPageTitle};
`

export const ResourceSection = styled.div`
  margin-bottom: 150px;
`

export const SectionTitle = styled.h4`
  padding-bottom: 28px;

  &:after {
    content: '';
    display: block;
    width: 64px;
    height: 4px;
    background-color: ${Colors.blue};
    margin-top: 20px;
  }
`
export const Links = styled.div``
export const ResourceLink = styled.div`
  margin-bottom: 60px;

  ${props => !props.hasTitle && css`
    margin-bottom: 0.7em;
  `}
`

export const ArticleTitle = styled.div`
  ${Type.bodyMedium};
  color: ${Colors.blue};
  transition: opacity .3s ease;
`

export const Source = styled.div`
  ${Type.navItem};
  color: ${Colors.darkGray};
  transition: opacity .3s ease;
  line-height: normal;

  @media(max-width: ${Breakpoints.main}){
    font-size: 16px;
  }
`

export const StyledA = styled.a`
  &:hover {
    ${ArticleTitle} {
      opacity: .5;
    }
    ${Source} {
      opacity: .5;
    }
  }
`

export const Fade = styled.div`
  position: absolute;
  bottom: -200px;
  left: 0;
  background-image: url(${fade});
  background-position: top left;
  background-repeat: repeat-x;
  background-size: 600px 200px;
  height: 200px;
  width: 100%;
`

export const MaxWidth = styled.div`
  ${Templates.contentPageMaxWidth};
`
