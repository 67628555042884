import React from 'react'
import { immigration, guns, healthcare, education, climateChange, mediaLiteracy } from 'app/assets/images'

export default {
  immigration: {
    title: 'Immigration policy',
    subtitle: 'We examine',
    description: <div>
      <div>What impact does immigration have on the U.S. economy?</div><br /><div>How does immigration influence U.S. identity?</div>
    </div>,
    image: immigration
  },
  guns: {
    title: 'Guns',
    subtitle: 'We examine',
    description: <div>
      <div>How have gun rights and regulation evolved in the U.S.?</div><br /><div>What can a public health approach tell us about gun violence?</div>
    </div>,
    image: guns
  },
  healthcare: {
    title: 'Healthcare',
    subtitle: 'We examine',
    description: <div>
      <div>What is the current state of the U.S. healthcare system?</div><br /><div>How does healthcare in the U.S. compare to other countries?</div>
    </div>,
    image: healthcare
  },
  education: {
    title: 'Education',
    subtitle: 'We examine',
    description: <div>
      <div>How has school segregation changed since Brown v. Board?</div><br /><div>What is the role of affirmative action in higher education?</div>
    </div>,
    image: education
  },
  climateChange: {
    title: 'Climate change',
    subtitle: 'We examine',
    description: <div>
      <div>How have past emissions contributed to global warming?</div><br /><div>What can communities do to adapt to climate change?</div>
    </div>,
    image: climateChange
  },
  mediaLiteracy: {
    title: 'Media literacy',
    subtitle: 'We examine',
    description: <div>
      <div>What does “objectivity” in news mean?</div><br /><div>How can I stay informed without feeling overwhelmed?</div>
    </div>,
    image: mediaLiteracy
  }
}
