import React, { Component } from 'react'

import * as S from './styles'

export default class Carousel extends Component {
  state = { selectedIndex: 0 }

  componentDidMount = () => {
    if (this.flickityRef) this.onChange()

    if (typeof window !== 'undefined') {
      const Flickity = require('react-flickity-component')
      this.setState({ Flickity })
    }
  }

  onChange = () => {
    this.flickityRef.on('change', (index) => {
      if (this.props.onChange) {
        this.props.onChange(index)
      }
    })
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (!prevState.Flickity && this.state.Flickity && this.flickityRef) this.onChange()

    const didGlobalIndexChange = this.props.selectedIndex !== this.flickityRef.selectedIndex

    if (didGlobalIndexChange) {
      this.flickityRef.select(this.props.selectedIndex)
    }
  }

  render () {
    const { Flickity } = this.state
    const { selectedIndex } = this.props
    if (!Flickity) return <S.CarouselComponent />

    const { children, options, className, withPagination, paginationType } = this.props
    const nextEnabled = options.wrapAround ? true : selectedIndex + 1 !== children.length
    const previousEnabled = options.wrapAround ? true : selectedIndex + 1 !== 1

    return <S.CarouselComponent>
      <Flickity options={options} className={className} flickityRef={c => { this.flickityRef = c }}>
        <S.FlickityStyles />
        { children }
      </Flickity>
      { withPagination &&
        <S.Pagination paginationType={paginationType}>
          <S.Previous enabled={previousEnabled} paginationType={paginationType} onClick={() => this.flickityRef.previous()}><S.StyledLeftArrow /></S.Previous>
          <S.Next enabled={nextEnabled} paginationType={paginationType} onClick={() => this.flickityRef.next()}><S.StyledLeftArrow /></S.Next>
        </S.Pagination>
      }
    </S.CarouselComponent>
  }
}
