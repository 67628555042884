import styled, { css } from 'styled-components'

import { Breakpoints, Colors, Type, Templates } from 'app/styles'

export const ReflectionComponent = styled.div`
  ${Templates.solutionBox};
  display: flex;
  flex-direction: column;
  justify-content: center;
`
export const Title = styled.div`
  ${Type.questionBodySmall};
  color: ${Colors.periwinkle};
  margin-bottom: 5px;

  @media(max-width: ${Breakpoints.solutionComponent3}){
    text-align: center;
    margin-bottom: 20px;
  }
`

export const RatingPicker = styled.div`
  display: flex;
  flex-direction: column;
`

export const Labels = styled.div`
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  margin-bottom: 10px;
`

export const Label = styled.div`
  text-transform: uppercase;
  color: ${Colors.periwinkle};
  font-size: 12px;
  font-weight: bold;
`

export const Ratings = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Rating = styled.div`
  width: 65px;
  height: 65px;
  background-color: ${Colors.peach};
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${Colors.white};
  cursor: pointer;
  transition: transform .2s ease-out;
  font-weight: 600;

  &:hover {
    transform: scale(1.1);
  }

  ${props => props.selected && css`
    transform: scale(1.25);

    &:hover {
      transform: scale(1.25);
    }
  `}

  ${props => props.unselected && css`
    transform: scale(0.75);
    background-color: ${Colors.peachLight};
  `}

  @media(max-width: ${Breakpoints.solutionComponent3}){
    width: 43px;
    height: 43px;
  }
`
