import React, { Component } from 'react'

import * as S from './styles'

export default class HowItWorksCard extends Component {
  render () {
    const { data } = this.props
    return <S.HowItWorksCardComponent>
      <S.IconAndTitle>
        <data.icon />
        <S.Title>{data.title}</S.Title>
      </S.IconAndTitle>
      <S.Description>{data.description}</S.Description>
    </S.HowItWorksCardComponent>
  }
}
