export const main = '1073px'
export const smallest = '834px'
export const largest = '1300px'
export const floatingNewsAdjust = '1110px'
export const iphone5 = '374px'

export const solutionComponent1 = '1160px'
export const solutionComponent2 = '1074px'
export const solutionComponent3 = '700px'
export const icebergPosition = '485px'
