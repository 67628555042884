import styled from 'styled-components'

import { Breakpoints, Colors, Type } from 'app/styles'
import { periwinkleWaveLarge } from 'app/assets/images'

export const HelpReduceComponent = styled.div`
  background-color: ${Colors.periwinkle};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 180px 0 142px 0;
  position: relative;
  margin-top: 400px;

  @media(max-width: ${Breakpoints.main}){
    margin-top: 230px;
    padding: 180px 0 72px 0;
  }
`

export const ContentWrap = styled.div`
  position: relative;
  margin-top: -431px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media(max-width: ${Breakpoints.main}){
    margin-top: -360px;
  }
`

export const Title = styled.div`
  color: ${Colors.white};
  max-width: 620px;
  padding: 0 25px;
  box-sizing: border-box;
  text-align: center;
  margin-bottom: 25px;
  font-size: 48px;
  line-height: 52px;
  font-family: ${Type.secondaryFontFace};
  font-weight: bold;

  @media(max-width: ${Breakpoints.main}){
    font-size: 32px;
    line-height: 36px;
  }
`

export const Description = styled.div`
  ${Type.body};
  color: ${Colors.white};
  max-width: 820px;
  padding: 0 25px;
  box-sizing: border-box;
  text-align: center;
  margin-bottom: 1.06em;

  &:last-child {
    margin-bottom: 62px;

    @media(max-width: ${Breakpoints.main}){
      margin-bottom: 50px;
    }
  }

  @media(max-width: ${Breakpoints.main}){
    max-width: 600px;
  }
`

export const PeriwinkleWave = styled.div`
  position: absolute;
  top: -461px;
  background-image: url(${periwinkleWaveLarge});
  background-repeat: no-repeat;
  background-size: 1800px auto;
  background-position: top;
  width: 1800px;
  height: 461px;

  @media(max-width: ${Breakpoints.main}){
    width: 1322px;
    height: 285px;
    background-size: 1322px auto;
    top: -285px;
  }
`
