import React, { Component } from 'react'

import * as S from './styles'

import { mobileMonk } from 'app/assets/images'

export default class NotFound extends Component {
  render () {
    return <S.NotFoundPage>
      <S.MaxWidth>
        <S.RadialGradient />
        <S.Stars />
        <S.Monk src={mobileMonk} />
        <S.Title>All who wander are not lost....<br />But it looks like you are.</S.Title>
        <S.Description>No need to panic. Take a deep breath in slowly through your nose. Hold it, count to three, then slowly breathe out. Now, let’s go find what you’re looking for.</S.Description>
        <S.StyledButton secondary>Back to homepage</S.StyledButton>
      </S.MaxWidth>
    </S.NotFoundPage>
  }
}
