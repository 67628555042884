import React, { Component } from 'react'

import * as S from './styles'

import { AppStoreButton } from 'app/components'

export default class HelpReduce extends Component {
  render () {
    return <S.HelpReduceComponent>
      <S.PeriwinkleWave />
      <S.ContentWrap>
        <S.Title>How can Sift help reduce your news anxiety?</S.Title>
        <div>
          <S.Description>Sift doesn’t just tell you what’s happening—instead, it helps you understand why and how we got here, and provides space to reflect, rather than simply react.</S.Description>
          <S.Description>Research shows that mindful awareness can radically reduce anxiety. We believe that zooming out from the endless news cycle to consider the bigger picture helps reframe the conventional narrative. Sift aims to foster a calmer state of mind and invite more productive, less polarizing conversations.</S.Description>
        </div>
        <AppStoreButton />
      </S.ContentWrap>
    </S.HelpReduceComponent>
  }
}
