import React, { Component } from 'react'

import * as S from './styles'
import {
  personShadow,
  mobileBackPapers,
  mobileFrontPapers,
  mobileLeftPapers,
  mobileLeftSmallPapers,
  mobileRightSmallPapers,
  mobileYellowPage
} from 'app/assets/images'
import { getIn } from 'app/utilities'
import { floatingNewsImageMap } from 'app/data'

export default class FloatingNewsMobile extends Component {
  render () {
    const imageUrl = getIn(floatingNewsImageMap[this.props.floatingPersonNum], ['mobileImage'])
    return <S.FloatingNewsMobileComponent>
      <S.FloatingGirl src={imageUrl} />
      <S.PersonShadow src={personShadow} />
      <S.MobileBackPapers src={mobileBackPapers} />
      <S.MobileFrontPapers src={mobileFrontPapers} />
      <S.MobileLeftPapers src={mobileLeftPapers} />
      <S.MobileLeftSmallPapers src={mobileLeftSmallPapers} />
      <S.MobileRightSmallPapers src={mobileRightSmallPapers} />
      <S.MobileYellowPage src={mobileYellowPage} />
    </S.FloatingNewsMobileComponent>
  }
}
