import React, { Component } from 'react'
import Swipe from 'react-easy-swipe'
import { withRouter } from 'react-router-dom'

import * as S from './styles'
import { CorrectnessIndicator } from 'app/components'
import Analytics from 'app/analytics'

const questions = {
  mexican: {
    slug: 'mexican',
    display: 'Mexican',
    correctAnswer: 1
  },
  nativeAmerican: {
    slug: 'nativeAmerican',
    display: 'Native American',
    correctAnswer: -1
  },
  southAsian: {
    slug: 'southAsian',
    display: 'South Asian',
    correctAnswer: 1
  }
}

@withRouter
export default class Quiz extends Component {
  state = { questions: null }
  componentDidMount = () => {
    Object.keys(questions).forEach(item => {
      this.setState({ [item]: 0 })
    })
  }

  setRating = ({ slug, value }) => {
    this.setState({ [slug]: value })

    Analytics.event({
      eventType: 'WIDGET INTERACTION',
      pathname: this.props.location.pathname,
      props: {
        widgetName: 'Quiz',
        slug,
        value
      }
    })
  }

  renderQuestion = (data) => {
    const correct = this.state[data.slug] === questions[data.slug].correctAnswer
    const incorrect = this.state[data.slug] !== questions[data.slug].correctAnswer && this.state[data.slug] !== 0
    const position = this.state[data.slug]

    let correctness
    if (correct) {
      correctness = 'correct'
    } else if (incorrect) {
      correctness = 'incorrect'
    } else {
      correctness = null
    }
    return <S.Question key={data.slug}>
      <S.DottedLine />
      <S.StyledMinus onClick={() => { this.setRating({ slug: data.slug, value: -1 }) }} />
      <Swipe
        onSwipeRight={() => { this.setRating({ slug: data.slug, value: 1 }) }}
        onSwipeLeft={() => { this.setRating({ slug: data.slug, value: -1 }) }}
        allowMouseEvents
      >
        <S.QuestionTitle
          position={position}
          correct={correct}
          incorrect={incorrect}
          value={this.state[data.slug]}
        >
          <S.ChoiceText state={correctness}>{data.display}</S.ChoiceText>
          <CorrectnessIndicator state={correctness} />
        </S.QuestionTitle>
      </Swipe>
      <S.StyledPlus onClick={() => { this.setRating({ slug: data.slug, value: 1 }) }} />
    </S.Question>
  }
  render () {
    return <S.QuizComponent>
      <S.Title>Which groups were at some point classified as “white” by the Census?</S.Title>
      <S.Quiz>
        <S.Labels>
          <S.Label>Never considered white</S.Label>
          <S.Label>Once considered white</S.Label>
        </S.Labels>
        <S.Questions>
          { Object.values(questions).map(this.renderQuestion) }
        </S.Questions>
      </S.Quiz>
    </S.QuizComponent>
  }
}
