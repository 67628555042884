import styled from 'styled-components'

import { phone, iPhoneXSpeaker } from 'app/assets/images'
import { Colors, Type, Breakpoints } from 'app/styles'

export const PhoneComponent = styled.div`
  background-image: url(${phone});
  width: 568px;
  height: 944px;
  background-size: cover;
  background-repeat: no-repeat;
  flex-shrink: 0;
  position: relative;

  @media(max-width: ${Breakpoints.main}){
    background-image: none;
    width: 100vw;
    height: auto;
  }
`

export const Darken = styled.div`
  width: 322px;
  height: 300px;
  position: absolute;
  top: 0px;
  left: 0px;
  background-image: linear-gradient(157.67deg, rgba(119, 133, 150, 0.7) 16.73%, rgba(71, 106, 148, 0) 75.91%);
  mix-blend-mode: multiply;
  z-index: 1;

  @media(max-width: ${Breakpoints.main}){
    top: -150px;
  }
`

export const Speaker = styled.div`
  position: absolute;
  width: 198.5px;
  height: 25px;
  top: 104px;
  left: 50%;
  margin-left: -99px;
  background-image: url(${iPhoneXSpeaker});
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 3;

  @media(max-width: ${Breakpoints.main}){
    display: none;
  }
`

export const CarouselWrap = styled.div`
  width: 322px;
  margin-bottom: 96px;
  height: 702px;
  margin: 0 auto;
  top: 105px;
  position: relative;

  @media(max-width: ${Breakpoints.main}){
    border-radius: 0;
    width: 100vw;
    height: auto;
    top: 0;
  }
`
export const Slide = styled.div`
  position: relative;
  width: 322px;

  @media(max-width: ${Breakpoints.main}){
    background-color: ${Colors.white};
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-right: 20px;
    height: 382px;
    width: 270px;
  }
`
export const SlideText = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  padding: 70px 40px;
  z-index: 2;

  @media(max-width: ${Breakpoints.main}){
    padding: 27px;
  }
`
export const Title = styled.div`
  ${Type.slideTitleBig};
  color: ${Colors.white};
`

export const ImageWrap = styled.div`
  height: 436px;
  overflow: hidden;
  border-radius: 40px 40px 0 0;
  position: relative;

  @media(max-width: ${Breakpoints.main}){
    height: 152px;
    border-radius: 0;
  }
`
export const CarouselImage = styled.img`
  width: 322px;
  height: auto;
  position: relative;

  @media(max-width: ${Breakpoints.main}){
    top: -190px;
  }
`

export const StyledA = styled.a`
  &:hover {
    text-decoration: none;
  }
`

export const Content = styled.div`
  margin-left: 50px;
`

export const SlideSubText = styled.div`
  padding: 44px 30px 0 42px;
  background-color: ${Colors.white};
  border-radius: 0 0 40px 40px;
  overflow: hidden;
  color: ${Colors.violetDark};
  height: 268px;
  box-sizing: border-box;

  @media(max-width: ${Breakpoints.main}){
    padding: 23px 27px;
  }
`

export const SlideSubTitle = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${Colors.blue};
  text-transform: uppercase;
  margin-bottom: 10px;
`
export const SlideDescription = styled.div`
  ${Type.slideBody};
  ${Type.bodyPensumPro};
`
