import Root from '../components/Root/Root'
import Home from '../pages/Home'
import NotFound from '../components/NotFound/NotFound'
import WhySift from '../pages/WhySift/WhySift'
import Resources from '../pages/Resources/Resources'
import PrivacyPolicy from '../pages/PrivacyPolicy/PrivacyPolicy'
import TermsOfUse from '../pages/TermsOfUse/TermsOfUse'
import Faq from '../pages/Faq/Faq'
import FaqWebView from '../pages/FaqWebView/FaqWebView'

import navThemes from './navThemes'

// Navigation is themeable for both mobile and desktop
// see navThemes.js to customize themes,
// and set them on each route with navTheme: navThemes.myTheme

export default [{
  component: Root,
  routes: [{
    path: '/',
    exact: true,
    component: Home,
    navTheme: navThemes.home,
    meta: {
      title: 'Sift - Feel news differently',
      description: 'Sift unpacks contentious issues to help reduce news anxiety'
    }
  }, {
    path: '/why-sift',
    exact: true,
    component: WhySift,
    navTheme: navThemes.whySift,
    meta: {
      title: 'Sift - Why Sift?',
      description: 'Sift offers a break from the never-ending news cycle—a space where you can pause and think about the issues behind the headlines.'
    }
  }, {
    path: '/resources',
    exact: true,
    component: Resources,
    navTheme: navThemes.resources,
    meta: {
      title: 'Sift - Resources',
      description: ''
    }
  }, {
    path: '/terms-of-use',
    exact: true,
    component: TermsOfUse,
    navTheme: navThemes.privacyPolicy,
    meta: {
      title: 'Sift - Terms of Use',
      description: ''
    }
  }, {
    path: '/privacy-policy',
    exact: true,
    component: PrivacyPolicy,
    navTheme: navThemes.privacyPolicy,
    meta: {
      title: 'Sift - Privacy Policy',
      description: ''
    }
  }, {
    path: '/privacy_policy/index.html',
    exact: true,
    component: PrivacyPolicy,
    navTheme: 'hidden',
    meta: {
      title: 'Sift - Privacy Policy',
      description: ''
    }
  }, {
    path: '/privacy_policy',
    exact: true,
    component: PrivacyPolicy,
    navTheme: 'hidden',
    meta: {
      title: 'Sift - Privacy Policy',
      description: ''
    }
  }, {
    path: '/faq',
    exact: true,
    component: FaqWebView,
    navTheme: 'hidden',
    meta: {
      title: 'Sift - Faq',
      description: 'Frequently asked questions'
    }
  }, {
    path: '/frequently-asked-questions',
    exact: true,
    component: Faq,
    navTheme: navThemes.faq,
    meta: {
      title: 'Sift - Faq',
      description: 'Frequently asked questions'
    }
  }, {
    path: '*',
    component: NotFound,
    navTheme: navThemes.notFound,
    meta: {
      title: 'Sift - Not found',
      description: '404'
    }
  }]
}]
