import {
  floatingGirl,
  business,
  monk,
  grandpa,
  floatingGirlMobile,
  mobileBusiness,
  mobileMonk,
  mobileGrandpa
} from 'app/assets/images'

export default {
  0: {
    name: 'Girl',
    desktopImage: floatingGirl,
    mobileImage: floatingGirlMobile
  },
  1: {
    name: 'Business',
    desktopImage: business,
    mobileImage: mobileBusiness
  },
  2: {
    name: 'Monk',
    desktopImage: monk,
    mobileImage: mobileMonk
  },
  3: {
    name: 'Grandpa',
    desktopImage: grandpa,
    mobileImage: mobileGrandpa
  }
}
