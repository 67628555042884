export default {
  0: {
    display: 'Why Sift?',
    url: '/why-sift'
  },
  1: {
    display: 'FAQ',
    url: '/frequently-asked-questions'
  },
  2: {
    display: 'Resources',
    url: '/resources'
  }
}
