import React, { PureComponent } from 'react'

import * as S from './styles'
import { Phone, TopicsCarousel } from 'app/components'

export default class Topics extends PureComponent {
  render () {
    return <S.TopicsComponent>
      <S.SkyBlueWave />
      <S.TopicsContent>
        <S.MobileAppStoreButton />
        <Phone />
        <S.Content>
          <S.StyledH5>Topics</S.StyledH5>
          <S.StyledH2>We deliberately<br />choose tough topics</S.StyledH2>
          <S.Description>Sift explores some of the most contentious issues of our time, so we can develop a deeper understanding of the world around us.</S.Description>
          <TopicsCarousel />
          <S.DesktopAppStoreButton />
        </S.Content>
      </S.TopicsContent>

    </S.TopicsComponent>
  }
}
