import firebase from 'firebase/app'
import 'firebase/database'
import { initializeFirebase } from '../initializeFirebase'
import FirebaseService from './FirebaseService'

initializeFirebase()

const path = 'contactUs'
const DB = firebase.database()
const REF = DB.ref(path)

export default class ContactUs extends FirebaseService {
  static create = async ({ email, message, name }) => {
    await REF.push({ email, message, name })
  }
}
