import styled, { css } from 'styled-components'

import { Colors } from 'app/styles'

export const BlankComponent = styled.div`
  display: inline;
  border-bottom: 1.5px solid ${Colors.periwinkle};
`
export const Answer = styled.span`
  opacity: 0;
  transition: opacity .3s ease-in-out;
  font-weight: 600;

  ${props => props.filledIn && css`
    opacity: 1;
  `}
`
