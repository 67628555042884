import React, { Component } from 'react'

import * as S from './styles'

import { Carousel } from 'app/ui-kit'
import { topicsList } from 'app/data'
import { setTopicsCarouselIndex } from 'app/store/app/actions'
import { connect } from 'react-redux'

const mapStateToProps = (state) => {
  const { topicsCarouselIndex } = state.app
  return { topicsCarouselIndex }
}

@connect(mapStateToProps, { setTopicsCarouselIndex })
export default class TopicsCarousel extends Component {
  constructor (props) {
    super(props)
    this.carouselIndex = 0
  }
  onChange = (data) => {
    this.carouselIndex = data
    this.props.setTopicsCarouselIndex(data)
  }

  shouldComponentUpdate (nextProps) {
    if (nextProps.topicsCarouselIndex === this.carouselIndex) return false
    return true
  }

  renderSlide = (data, index) => {
    return <S.Slide key={data.title}>
      <S.Darken />
      <S.CarouselImage src={data.image} />
      <S.SlideText>
        <S.Title>{data.title}</S.Title>
      </S.SlideText>
    </S.Slide>
  }

  render () {
    return <S.TopicsCarouselComponent>
      <S.CarouselWrap>
        <Carousel
          options={{ prevNextButtons: false, pageDots: false, wrapAround: true, cellAlign: 'left', arrowShape: 'M7.51074 11.3828L2.34053 6.49983L7.51074 1.61685' }}
          onChange={this.onChange}
          selectedIndex={this.props.topicsCarouselIndex}
          withPagination
          name='topics'
        >
          { Object.values(topicsList).map(this.renderSlide)}
        </Carousel>
      </S.CarouselWrap>
    </S.TopicsCarouselComponent>
  }
}
