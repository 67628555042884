import styled from 'styled-components'

import { Breakpoints, Colors } from 'app/styles'
import { Button } from 'app/ui-kit'
import { bottomWave } from 'app/assets/images'

export const ContactComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  background-color: transparent;
  background-image: url(${bottomWave});
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  margin-top: -64px;
  height: 679px;
  box-sizing: border-box;
  z-index: 11;
  margin-bottom: -1px;
  width: 100%;

  @media(max-width: ${Breakpoints.main}){
    height: 367px;
    min-width: 100vw;
  }

  @media(max-width: ${Breakpoints.smallest}){
    height: auto;
    padding-bottom: 92px;
    padding-top: 92px;
  }
`

export const Buttons = styled.div`
  display: flex;

  @media(max-width: ${Breakpoints.smallest}){
    flex-direction: column;
  }
`

export const Description = styled.div`
  text-align: center;
  max-width: 640px;
  margin-bottom: 62px;
  color: ${Colors.white};
  max-width: 448px;
  padding: 0 25px;

  @media(max-width: ${Breakpoints.main}){
    margin-bottom: 37px;
    max-width: initial;
  }

  @media(max-width: ${Breakpoints.smallest}){
    max-width: 332px;
    box-sizing: border-box;
  }
`

export const Title = styled.h3`
  color: ${Colors.white};
  text-align: center;
  margin-bottom: 6px;
  max-width: 514px;
  margin-bottom: 15px;
  padding: 0 25px;

  @media(max-width: ${Breakpoints.smallest}){
    max-width: 324px;
    box-sizing: border-box;
  }
`

export const FaqButton = styled(Button)`
  margin-right: 24px;

  @media(max-width: ${Breakpoints.main}){
    margin-right: 30px;
  }

  @media(max-width: ${Breakpoints.smallest}){
    margin-right: 0;
    margin-bottom: 22px;
    width: 260px;
  }
`
export const StyledButton = styled(Button)`
  @media(max-width: ${Breakpoints.smallest}){
    width: 260px;
  }
`
