import React, { Component } from 'react'

import * as S from './styles'

export default class SourceAsterisk extends Component {
  render () {
    const { open } = this.props
    return <S.SourceAsteriskComponent>
      <svg width='12' height='12' viewBox='0 0 12 12' fill='none'>
        <S.Line1 open={open} d='M6 1L6 11' stroke='#48A1BD' strokeWidth='1.5' strokeLinecap='round' />
        <S.Line2 open={open} d='M1.49995 3.50003L10.4999 8.49985' stroke='#48A1BD' strokeWidth='1.5' strokeLinecap='round' />
        <S.Line3 open={open} d='M10.5 3.5L1.5 8.5' stroke='#48A1BD' strokeWidth='1.5' strokeLinecap='round' />
      </svg>
    </S.SourceAsteriskComponent>
  }
}
