import React, { PureComponent } from 'react'
import { withRouter } from 'react-router-dom'

import * as S from './styles'
import { Carousel } from 'app/ui-kit'
import graphCarouselData from './graphCarouselData'
import Analytics from 'app/analytics'

@withRouter
export default class GraphCarousel extends PureComponent {
  state = { carouselIndex: 0 }
  onChange = (data) => {
    this.setState({ carouselIndex: data })

    Analytics.event({
      eventType: 'WIDGET INTERACTION',
      pathname: this.props.location.pathname,
      props: {
        widgetName: 'Graph carousel',
        slug: 'index',
        value: data
      }
    })
  }

  renderText = (data, index) => {
    const { carouselIndex } = this.state
    return <S.TextWrap key={index} selected={carouselIndex === index}>
      <S.Title>{data.title}</S.Title>
      <S.Description>{data.description}</S.Description>
    </S.TextWrap>
  }

  renderSlide = (data, index) => {
    return <S.ImageWrap key={index}><S.StyledImage src={data.imageUrl} key={index} /></S.ImageWrap>
  }

  render () {
    const { carouselIndex } = this.state

    return <S.GraphCarouselComponent>
      <S.Texts>
        { Object.values(graphCarouselData).map(this.renderText)}
      </S.Texts>
      <S.CarouselWrap>
        <S.DateMarkerWrap><S.StyledDateMarker /></S.DateMarkerWrap>
        <S.Year>{ Object.values(graphCarouselData)[carouselIndex].year }</S.Year>
        <Carousel
          options={{ prevNextButtons: false, wrapAround: false }}
          withPagination
          paginationType='graph'
          onChange={this.onChange}
        >
          { Object.values(graphCarouselData).map(this.renderSlide)}
        </Carousel>
      </S.CarouselWrap>
    </S.GraphCarouselComponent>
  }
}
