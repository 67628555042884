import React, { Component } from 'react'

import * as S from './styles'
import SourceAsterisk from '../SourceAsterisk/SourceAsterisk'

export default class TextWithSource extends Component {
  state = {
    open: false,
    maxHeight: 0,
    currentHeight: 'auto'
  }
  constructor (props) {
    super(props)
    this.element = React.createRef()
  }
  componentDidMount = () => {
    // Get the natural height of the source element, then set its height to 0
    const element = this.element.current
    element.style.position = 'absolute'
    const extraPadding = this.props.extraPadding ? 50 : 0
    this.setState({ maxHeight: element.offsetHeight + 30 + extraPadding }, () => {
      this.setState({ currentHeight: '0px', display: 'block' })
    })
  }
  toggleSource = async () => {
    const { open, maxHeight } = this.state
    const currentHeight = open ? '0px' : `${maxHeight}px`
    const display = open ? 'inline' : 'block'
    const position = open ? 'absolute' : 'relative'

    // Hide source
    if (open) {
      this.setState({ open: !open, currentHeight, animationComplete: false })
      await setTimeout(() => { this.setState({ display, position, animationComplete: true }) }, (S.animationDuration * 0.8) + S.delayDuration)

      // Expand source
    } else {
      this.setState({ display, animationComplete: false }, async () => {
        await setTimeout(() => { this.setState({ position, open: !open, currentHeight, animationComplete: true }) }, 1)
      })
    }
  }

  getLastWord = (string) => {
    const words = string.split(' ')
    return words[words.length - 1]
  }

  removeLastWord = (string) => {
    const lastIndex = string.lastIndexOf(' ')
    return string.substring(0, lastIndex)
  }

  renderSource = () => {
    const { source } = this.props
    const { open, currentHeight, display, position, maxHeight, animationComplete } = this.state

    const title = this.removeLastWord(source.title)
    const titleLastWord = this.getLastWord(source.title)

    if (maxHeight > 0 && !open && animationComplete) return null
    return <S.Source ref={this.element} open={open} style={{ height: currentHeight, display, position }}>
      <S.SourceWrap>
        <S.Author open={open}>{source.author}</S.Author>
        <S.TitleAndArrow>
          <S.Title href={source.url} target='_blank'>{title}<S.LastWordAndArrow> {titleLastWord}<S.Arrow /></S.LastWordAndArrow></S.Title>
        </S.TitleAndArrow>
        <S.Quote open={open}>{source.quote}</S.Quote>
      </S.SourceWrap>
    </S.Source>
  }

  render () {
    const { children } = this.props
    const { open } = this.state

    const text = this.removeLastWord(children)
    const textLastWord = this.getLastWord(children)

    return <S.TextWithSourceComponent open={open}>
      <S.TextWrap onClick={this.toggleSource}>
        <S.Span open={open} >{text}</S.Span>
        <S.LastWordAndArrow>
          <S.Span open={open}> {textLastWord}</S.Span>
          <S.SourceToggle>
            <SourceAsterisk open={open} />
          </S.SourceToggle>
        </S.LastWordAndArrow>
      </S.TextWrap>

      { this.renderSource() }
    </S.TextWithSourceComponent>
  }
}
