import styled from 'styled-components'

import { Breakpoints, Type, Colors, Templates } from 'app/styles'
import { BreakerWave } from 'app/ui-kit/Icons'
import { fade, darkBlueWave } from 'app/assets/images'

export const StyledBreakerWave = styled(BreakerWave)`
  margin-top: -146px;
  margin-bottom: -260px;
  position: relative;

  @media(max-width: ${Breakpoints.smallest}){
    width: 1322px;
    height: 285px;
  }
`

export const FaqPage = styled.div`
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${Colors.white};
  background-image: url(${fade});
  background-position: top left;
  background-repeat: repeat-x;
  background-size: 600px 200px;
  padding: 0 0 100px 0;

  h4 {
    margin-bottom: 40px;
    margin-top: 82px;

    @media(max-width: ${Breakpoints.main}){
      margin-bottom: 30px;
      margin-top: 60px;
    }
  }

  p {
    ${Type.body};
  }

  a {
    color: ${Colors.blue};
    transition: opacity .3s ease;

    &:hover {
      opacity: 0.5;
    }

    &:active {
      opacity: 0.9;
    }
  }
`
export const MaxWidth = styled.div`
   ${Templates.contentPageMaxWidth};
`

export const PageTitle = styled.h2`
  ${Templates.contentPageTitle};
`

export const Fade = styled.div`
  position: absolute;
  bottom: -200px;
  left: 0;
  background-image: url(${fade});
  background-position: top left;
  background-repeat: repeat-x;
  background-size: 600px 200px;
  height: 200px;
  width: 100%;
`

export const Hero = styled.div`
  background-color: ${Colors.darkBlue};
  background-image: url(${darkBlueWave});
  background-position: center top;
  height: 550px;
  background-size: cover;
  width: 100%;
  margin-top: -140px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
`
