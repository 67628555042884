import styled, { css } from 'styled-components'

import { Breakpoints, Type, Colors } from 'app/styles'

export const FaqPage = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 0 100px 0;

  h4 {
    margin-bottom: 40px;
    margin-top: 82px;

    @media(max-width: ${Breakpoints.main}){
      margin-bottom: 30px;
      margin-top: 60px;
    }
  }

  p {
    ${Type.body};
  }

  a {
    color: ${Colors.blue};
    transition: opacity .3s ease;

    &:hover {
      opacity: 0.5;
    }

    &:active {
      opacity: 0.9;
    }
  }
`
export const MaxWidth = styled.div`
  max-width: 913px;
  box-sizing: border-box;
  padding: 0 80px;

  @media(max-width: ${Breakpoints.main}){
    padding: 0 25px;
  }
`

export const PageTitle = styled.h2`
  margin-top: 140px;
  margin-bottom: 100px;

  ${props => props.theme === 'hidden' && css`
    margin-top: 45px;
    margin-bottom: -15px;
  `}
`
