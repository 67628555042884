import styled from 'styled-components'

import { Colors, Breakpoints } from 'app/styles'
import { BreakerWave } from 'app/ui-kit/Icons'
import { fade } from 'app/assets/images'

export const StyledBreakerWave = styled(BreakerWave)`
  margin-top: -146px;
  margin-bottom: -182px;

  @media(max-width: ${Breakpoints.smallest}) {
    width: 1322px;
    height: 285px;
  }
`
export const SolutionComponent = styled.div`
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${Colors.white};
  background-image: url(${fade});
  background-position: top left;
  background-repeat: repeat-x;
  padding-bottom: 40px;
  background-size: 600px 200px;

  @media(max-width: ${Breakpoints.smallest}){
    background-image: none;
  }
`

export const StyledH5 = styled.h5`
  color: ${Colors.periwinkle};
  text-align: center;
  margin-bottom: 25px;

  @media(max-width: ${Breakpoints.solutionComponent3}){
    margin-bottom: 13px;
  }
`

export const StyledH2 = styled.h2`
  text-align: center;
`
export const ItalicH2 = styled.h2`
  text-align: center;
  font-style: italic;
`
export const SolutionSections = styled.div`
  position: relative;
  margin-top: 65px;

  @media(max-width: ${Breakpoints.solutionComponent3}){
    margin-top: 99px;
  }
`

export const Line = styled.div`
  position: absolute;
  left: 50%;
  margin-left: -1.5px;
  width: 3px;
  height: 100%;
  background-image: linear-gradient(white, ${Colors.periwinkle} 5%, ${Colors.periwinkle} 95%, white);

  @media(max-width: ${Breakpoints.solutionComponent2}){
    display: none;
  }
`
