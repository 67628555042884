
import { keyframes } from 'styled-components'
export const floatingGirlAnimation = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, 11px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
`

export const personShadowAnimation = keyframes`
  0% {
    transform: scaleX(1.25);
  }
  50% {
    transform: scaleX(0.8);
  }
  100% {
    transform: scaleX(1.25);
  }
`

export const newsAnimation1 = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, -4px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
`

export const newsAnimation2 = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, 7px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
`

export const newsAnimation3 = keyframes`
  0% {
    transform: translate3d(0, 0, 0);
  }
  50% {
    transform: translate3d(0, -10px, 0);
  }
  100% {
    transform: translate3d(0, 0, 0);
  }
`
