import React from 'react'

export default (props) =>
  <svg {...props} width='100' height='100' viewBox='0 0 100 100' fill='none'>
    <path d='M97.866 35.9029C97.7706 35.7121 97.4844 35.5213 97.2936 35.4259L52.7419 23.7871C52.2649 23.6917 51.6925 23.9779 51.5971 24.4549L50.6431 28.0801L48.4489 16.9183C48.3535 16.4413 47.8765 16.0597 47.3041 16.1551L2.08456 24.7411C1.60756 24.8365 1.22596 25.3135 1.32136 25.8859L5.61435 49.4496C10.1935 73.395 34.7113 75.8754 34.9975 75.8754H35.0929C35.2837 75.8754 35.3791 75.8754 35.5699 75.78C35.6653 75.6846 41.1985 72.6318 46.0639 66.717C50.2615 77.9742 59.9922 83.889 60.4692 84.0798C60.66 84.1752 60.7554 84.1752 60.9462 84.1752C61.2324 84.1752 85.8456 82.7442 91.8558 59.7528L97.9614 36.5707C98.0568 36.3799 97.9614 36.1891 97.866 35.9029ZM34.9021 73.9674C32.2309 73.5858 11.5291 70.3422 7.52235 49.068L3.42015 26.4583L46.8271 18.2539L51.1201 40.8637C54.9361 61.3746 37.1917 72.6318 34.9021 73.9674ZM90.0432 59.3712C84.7008 79.596 63.9036 82.0764 61.137 82.3626C59.5152 81.3132 50.8339 75.3984 47.3995 64.9998C51.5971 59.1804 54.8407 50.976 52.9327 40.5775L51.4063 32.3731C51.4063 32.2777 51.5017 32.1823 51.5017 32.1823L53.2189 25.9813L95.8626 37.1431L90.0432 59.3712Z' fill='#48A1BD' />
    <path d='M24.4074 40.6727C24.312 40.1957 23.835 39.8141 23.2626 39.9095L14.7721 41.5313C14.2951 41.6267 13.9135 42.1037 14.0089 42.6761C14.1043 43.1531 14.4859 43.4393 14.9629 43.4393C15.0583 43.4393 15.0583 43.4393 15.1537 43.4393L23.6442 41.8175C24.1212 41.7221 24.5028 41.2451 24.4074 40.6727Z' fill='#48A1BD' />
    <path d='M33.0879 40.0052C33.1833 40.0052 33.1833 40.0052 33.2787 40.0052L41.7692 38.3834C42.2462 38.288 42.6278 37.811 42.5324 37.2386C42.437 36.7616 41.96 36.38 41.3876 36.4754L32.8971 38.0972C32.4201 38.1926 32.0385 38.6696 32.1339 39.242C32.2293 39.6236 32.6109 40.0052 33.0879 40.0052Z' fill='#48A1BD' />
    <path d='M73.9212 50.5943L82.221 52.7885C82.3164 52.7885 82.4118 52.7885 82.5072 52.7885C82.8888 52.7885 83.2704 52.5023 83.4612 52.1207C83.5566 51.6437 83.2704 51.0713 82.7934 50.9759L74.4936 48.7817C74.0166 48.6863 73.4442 48.9725 73.3488 49.4495C73.158 49.9265 73.4442 50.4989 73.9212 50.5943Z' fill='#48A1BD' />
    <path d='M56.2706 45.9197L64.5704 48.1139C64.6658 48.1139 64.7612 48.1139 64.8566 48.1139C65.2382 48.1139 65.6198 47.8277 65.8106 47.4461C65.906 46.9691 65.6198 46.3967 65.1428 46.3013L56.843 44.1071C56.366 44.0117 55.7936 44.2979 55.6982 44.7749C55.412 45.2519 55.6982 45.8243 56.2706 45.9197Z' fill='#48A1BD' />
    <path d='M44.8249 51.93C44.3479 51.7392 43.7755 51.93 43.5847 52.407C41.7721 56.8908 37.7653 59.9436 32.3275 60.993C26.0311 62.1378 22.2151 60.1344 18.4945 56.6046C18.1129 56.223 17.5405 56.3184 17.1589 56.7C16.7773 57.0816 16.8727 57.654 17.2543 58.0356C20.2117 60.8022 23.7415 63.1872 28.9885 63.1872C30.1333 63.1872 31.3735 63.0918 32.7091 62.8056C38.8147 61.6608 43.2985 58.2264 45.3973 53.0748C45.5881 52.6932 45.3019 52.1208 44.8249 51.93Z' fill='#48A1BD' />
    <path d='M50.9303 62.9964C50.5487 63.378 50.5487 63.9504 50.8349 64.332C51.2165 64.7136 51.7889 64.7136 52.1705 64.4274C55.7957 61.1838 60.661 60.1344 66.0034 61.5654C72.2044 63.1872 74.7802 66.717 76.6882 71.487C76.879 71.8686 77.1652 72.0594 77.5468 72.0594C77.6422 72.0594 77.833 72.0594 77.9284 71.964C78.4054 71.7732 78.6916 71.2008 78.5008 70.7238C76.6882 66.1446 73.9216 61.6608 66.5758 59.6574C60.5656 58.131 55.0325 59.2758 50.9303 62.9964Z' fill='#48A1BD' />
  </svg>
