import React, { Component } from 'react'

import * as S from './styles'

export default class SuccessModal extends Component {
  render () {
    return <S.SuccessModalComponent>
      <S.Title>Thanks for taking the time to talk to us</S.Title>
    </S.SuccessModalComponent>
  }
}
