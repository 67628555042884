import styled from 'styled-components'

import { Colors, Shadows, Sizes, Breakpoints } from 'app/styles'
import { mobileHeroNoise } from 'app/assets/images'
import { Whisp } from 'app/components'

export const HomeComponent = styled.div`
  background: ${Colors.offWhite};
  display: flex;
  flex-direction: column;
  position: relative;

  @media(max-width: ${Breakpoints.smallest}){
    background: ${Colors.darkBlue};
  }
`

export const MobileHeroNoise = styled.div`
  display: none;

  @media(max-width: ${Breakpoints.smallest}){
    width: 100vw;
    background-image: url(${mobileHeroNoise});
    background-repeat: repeat-x;
    background-size: 375px 994px;
    top: 0;
    left: 0;
    z-index: 1;
    height: 994px;
    position: absolute;
    display: block;
  }
`

export const Hero = styled.div`
  display: flex;
  margin: 180px 0 240px 0;
  max-width: calc(${Sizes.maxWidth} + 112px);
  align-self: center;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
  z-index: 5;
  position: relative;
  box-sizing: border-box;
  padding: 0 56px;

  @media(max-width: ${Breakpoints.main}){
    margin: 170px 0 180px 0;
  }

  @media(max-width: ${Breakpoints.smallest}) {
    margin: 100px 0 80px 0;
    padding: 0 25px;
    height: auto;
    flex-direction: column;
    align-items: center;
  }
`

export const HeroTitle = styled.h1`
  margin-bottom: 38px;

  @media(max-width: ${Breakpoints.main}){
    margin-bottom: 14px;
  }

  @media(max-width: ${Breakpoints.smallest}){
    text-align: center;
    margin-bottom: 18px;
    color: ${Colors.white};
  }
`
export const RightWhisp = styled(Whisp)`
  right: 0;
  top: -240px;
  > div {
    background-position: -120px 0;
  }

  @media(max-width: ${Breakpoints.main}) {
    display: none;
  }
`

export const LeftWhisp = styled(Whisp)`
  position: absolute;
  left: 0;
  top: 400px;

  @media(max-width: ${Breakpoints.main}) {
    display: none;
  }
`

export const HeroDescription = styled.div`
  margin-bottom: 40px;

  @media(max-width: ${Breakpoints.main}){
    margin-bottom: 25px;
  }

  @media(max-width: ${Breakpoints.smallest}){
    margin-bottom: 30px;
    text-align: center;
    padding: 0 20px;
  }
`
export const Copy = styled.div`
  width: 484px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media(max-width: ${Breakpoints.main}){
    max-width: 290px;
  }

  @media(max-width: ${Breakpoints.smallest}){
    align-items: center;
    max-width: 327px;
    color: ${Colors.white};
    margin-top: 10px;
  }
`
export const News = styled.div`
  width: 520px;
  height: 520px;
  box-shadow: ${Shadows.main};
  border-radius: 48px;
`
