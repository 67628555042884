import { css } from 'styled-components'

import { Colors, Breakpoints, Sizes } from 'app/styles'

export const solutionBox = css`
  background-color: ${Colors.white};
  border-radius: 12px;
  box-shadow: 0 0 30px 0 rgba(182, 207, 217, .15);
  width: 560px;
  height: 410px;
  padding: 40px 45px;
  box-sizing: border-box;
  position: relative;
  z-index: 2;

  @media(max-width: ${Breakpoints.solutionComponent3}){
    box-shadow: none;
    width: auto;
    padding: 12px 25px 0 25px;
    height: auto;
  }

  @media(max-width: ${Breakpoints.iphone5}){
    box-shadow: none;
    width: auto;
    padding: 12px 5px 0 5px;
    height: auto;
  }
`

export const card = css`
  background-color: ${Colors.white};
  border-radius: 12px;
  box-shadow: 0 0 30px 0 rgba(182, 207, 217, .15);
  width: 520px;
  height: 325px;
  padding: 25px 30px;
  box-sizing: border-box;
  position: relative;
  z-index: 2;

  @media(max-width: ${Breakpoints.smallest}) {
    width: auto;
    height: auto;
    padding: 20px;
    max-width: 400px;
  }
`

export const contentPageTitle = css`
  margin-bottom: 160px;
  color: ${Colors.white};
  box-sizing: border-box;
  padding: 0 80px;
  position: relative;
  z-index: 2;
  width: 913px;

  @media(max-width: ${Breakpoints.main}){
    width: 100vw;
    padding: 0 150px;
  }

  @media(max-width: ${Breakpoints.smallest}) {
    padding: 0 ${Sizes.xPaddingSmallest};
  }
`

export const contentPageMaxWidth = css`
  width: 913px;
  box-sizing: border-box;
  padding: 0 80px;
  position: relative;
  z-index: 2;

  @media(max-width: ${Breakpoints.main}) {
    padding: 0 150px;
    width: 100vw;
  }

  @media(max-width: ${Breakpoints.smallest}) {
    padding: 0 ${Sizes.xPaddingSmallest};
  }
`
